import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root',
})
export class MainService {
  public menuSelected = new BehaviorSubject(null);

  public baseURLMAPA =
    'https://maps.googleapis.com/maps/api/geocode/json?address=';
  public baseURLMAPALATLON =
    'https://maps.googleapis.com/maps/api/geocode/json?latlng=';
  public apiKey = '&key=AIzaSyCjgDSWwtQstvHkCB-gytm1l_o7ByUJMyw';

  public baseURL: string = 'https://admin.yoferretero.com/';
  public baseURLssr: string = 'https://www.yoferretero.com/';
  //public baseURL: String = "http://localhost:8081/";
  public headers: HttpHeaders;

  constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.set('Content-Type', 'application/json');
  }

  get(object: String) {
    return this.http.get(this.baseURL + '' + object, { headers: this.headers });
  }

  post(object: String, data: any) {
    return this.http.post(this.baseURL + '' + object, JSON.stringify(data), {
      headers: this.headers,
    });
  }

  delete(object: String) {
    return this.http.delete(this.baseURL + '' + object, {
      headers: this.headers,
    });
  }

  put(object: String, data: any) {
    return this.http.put(this.baseURL + '' + object, JSON.stringify(data), {
      headers: this.headers,
    });
  }

  sendEmail(data) {
    return this.http.post(this.baseURL + 'email/enviarCorreo', data, {
      headers: this.headers,
    });
  }
  /**
   * Cambiar el menu seleccionado en el menu lateral
   */
  changeMenu(data: number) {
    this.menuSelected.next(data);
  }
  /**
   * Metodo para obtener la coordenada de una direccion a traves del api de google
   * @param object String a evaluar y optener las coordenadas de la direccion
   */
  getUbicacionDireccion(object: string) {
    console.log(object);
    return this.http.get(this.baseURLMAPA + '' + object + this.apiKey, { headers: this.headers });
  }
  /**
   * Metodo para obtener la direccion desde coordenadas a traves del api de google
   * @param object String a evaluar y optener las coordenadas
   */
  getUbicacionCoordenadas(object: string): Observable<any> {
    return this.http.get(
      this.baseURLMAPALATLON +
      '' +
      object +
      '&result_type=neighborhood|political' +
      this.apiKey,
      {
        headers: this.headers,
      }
    );
  }
}
