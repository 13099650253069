<div *ngIf="cargaCompleta">
  <div class="container mx-auto">
    <div class="row marginR-0 marginL-0 marginT-20">
      <div class="col-sm-12 col-md-7 paddingL-0" *ngIf="noticiaPrincipal">
        <div class="row titulo-container marginB-20">
          <div class="col-12">
            <h3>
              Noticia Principal.
            </h3>
          </div>
          <div class="col-12" *ngIf="noticiaPrincipal">
            <div class="contenedor-noticia-principal">
              <a class="w-100" [routerLink]="['/noticias', noticiaPrincipal.slug]">
                <div class="noticia-principal" [ngStyle]="{
                  'background-image': 'url(' + noticiaPrincipal.imagen + ')'
                }">
                  <div class="gradiente-noticia-principal">
                    <p class=" text-wrap">
                      {{ noticiaPrincipal.titulo | slice: 0:50 }}
                    </p>
                    <div class="label-horas-principal">
                      {{ noticiaPrincipal.fechaPublicacion | datediff }}
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>

      </div>
      <div class="col-sm-12 col-md-5">
        <div class="row titulo-container">
          <div class="col-12">
            <h3>
              Noticias destacadas
            </h3>
          </div>

        </div>
        <div class="row ">
          <div class="col-12" *ngIf="usuarioCargado">
            <div *ngFor="let item of listaNoticiasDestacadas" class="marginB-12">
              <tarjeta-noticia [item]="item" [usuarioOb]="emisorUsuario" (modificando)="onModificando($event)"
                (modificado)="onNoticiaModificada($event)"></tarjeta-noticia>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="row marginL-0 marginR-0 marginT-20">
      <div class=" col-sm-12 col-md-7">
        <div class="row titulo-container marginB-20">
          <h3>
            Noticias Recientes
          </h3>
        </div>
        <div class="contenedor-noticia-principal " *ngIf="usuarioCargado">

          <div class="wrapper" *ngFor="let item of listaNoticias | slice: 0 : (cantNoticias*contVermas)">
            <tarjeta-noticia-contenido [item]="item" [usuarioOb]="emisorUsuario"
              (modificado)="onNoticiaModificada($event)" (modificando)="onModificando($event)">
            </tarjeta-noticia-contenido>
          </div>
          <div class="seccion-noticias">
            <button expand="block" type="button" class="boton-iniciar" [hidden]="!mostrarVerMas" (click)="vermas()">
              Ver mas
            </button>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-5" *ngIf="bannerInferior">
        <img class="img-fluid marginB-20 marginT-20" [src]="bannerSuperior.imagen.url" />
        <img class="img-fluid" [src]="bannerInferior.imagen.url" />
      </div>
    </div>
  </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p class="texto-blanco">Por favor espere...</p>
</ngx-spinner>
