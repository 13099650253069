<div class="seccion-noticias container-fluid">
  <div class="row">
    <div class="col-12 col-sm-6 col-md-5 col-xl-4 no-padding">
      <a class="w-100" [routerLink]="['/noticias', item.slug]"  ><div class="noticia-secundaria"
        [ngStyle]="{ 'background-image': 'url(' + item.imagen + ')' }">
      </div>
    </a>
    </div>

    <div class="col-12 col-sm-6 col-md-7 col-xl-8 paddinR-0">
      <div class="row">
        <div class="col-12 ">
          <a class="w-100" [routerLink]="['/noticias', item.slug]"  >
          <h6 class="texto-titulo">
            {{ item.titulo | slice: 0:150 }}
          </h6>
        </a>
        </div>
        <div class="col-8 col-sm-8 ">
          <div *ngIf="!usuarioLogueado">
            <div class="likes">{{item.likes + 40 | shortnumber}}</div>
            <img src="/assets/img/like-off.png" class="iconos-extras" alt="Debes iniciar sesión para dar like"
              (click)="toogleLike()" />
          </div>
          <div *ngIf="usuarioLogueado && usuario">
            <div class="likes">{{item.likes + 40 | shortnumber}}</div>
            <img *ngIf="!like" src="/assets/img/like-off.png" class="iconos-extras" (click)="toogleLike()" />
            <img *ngIf="like" src="/assets/img/like-on.png" class="iconos-extras" (click)="toogleLike()" />
            <span class="ion ion-md-share font-20" (click)="modalCompartir = true"></span>
          </div>
        </div>
        <div class="col-4 col-sm-4  position-date">
          <div class="label-horas">{{ item.fechaPublicacion | datediff }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="modalCompartir">
  <div class="fondo-modal"></div>
  <div class="div-modal">
    <div class="row">
      <div class="col-12">
        <label class="titulo-modal float-r cursor" (click)="modalCompartir = false">X</label>
        <h6 class="texto-titulo2">
          Compartir
        </h6>
      </div>
      <div class="col-sm-2">
      </div>
      <div class="col-sm-2">
        <div class="boton-compartir">
          <button shareButton="facebook" [url]="enlaceShared()" class="btn btn-facebook" (click)="compartiendo()">
            <i class="fa fa-facebook"></i></button>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="boton-compartir">
          <button shareButton="twitter" [url]="enlaceShared()" (click)="compartiendo()"
            style="color: white; background: #4AB3F4 !important;width: 100%  !important; font-size: 20px  !important;"
            class="btn btn-twitter"><i class="fa fa-twitter"></i></button>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="boton-compartir">
          <button shareButton="linkedin" [url]="enlaceShared()"(click)="compartiendo()"
            style="color: white; background: #1178B3 !important;width: 100%  !important; font-size: 20px  !important;"
            class="btn btn-linkedin"><i class="fa fa-linkedin"></i></button>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="boton-compartir">
          <button shareButton="whatsapp" [url]="enlaceShared()" class="btn btn-whatsapp" (click)="compartiendo()"><i
              class="fa fa-whatsapp"></i></button>
        </div>
      </div>
    </div>
  </div>
</div>
