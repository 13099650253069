<div class="fondo-modal"></div>
<div class="div-modal">
  <div class="row">
    <div class="col-12">
      <label class="titulo-modal float-r cursor" (click)="cerrarModal(false)">X</label>
    </div>
  </div>
  <div>
    <form [formGroup]="frmEnvio" (ngSubmit)="onEnviar()" novalidate>
      <div class="row">
        <div class="col-12">
          <label class="titulo-modal">Contacto</label>
        </div>
        <div class="col-12 marginT-20">
          <i class="fa fa-user icono"></i
          ><label class="label-bold">{{ usuario.nombre }}</label>
        </div>
        <div class="col-12">
          <i class="fa fa-envelope icono"></i
          ><label class="label-bold">{{ usuario.email }}</label>
        </div>
        <div class="col-12" [hidden]="usuario.celular === ''">
          <i class="fa fa-phone icono"></i
          ><label class="label-bold">{{ usuario.celular }}</label>
        </div>
        <div class="col-12" *ngIf="mostrarUnidades">
          <i class="fa fa-plus-circle icono"></i
          ><label class="label-bold">{{ param.nombreOferta }}</label>
        </div>
      </div>
      <div class="row marginT-20">
        <div class="col-12 col-sm-6" [formGroup]="frmEnvio">
          <label class="label">NIT o cédula</label>
          <input
            class="form-control input-login"
            formControlName="nit"
            [ngClass]="{ 'is-invalid': submited && f.nit.errors }"
            type="text"
            placeholder="Escribe aquí"
          />
          <div *ngIf="submited && f.nit.errors" class="invalid-feedback">
            <div *ngIf="f.nit.errors.required">NIT requerido</div>
          </div>
        </div>
        <div class="col-12 col-sm-6" [formGroup]="frmEnvio">
          <label class="label">Ciudad</label>
          <input
            class="form-control input-login"
            formControlName="ciudad"
            [ngClass]="{ 'is-invalid': submited && f.ciudad.errors }"
            type="text"
            placeholder="Escribe aquí"
          />
          <div *ngIf="submited && f.ciudad.errors" class="invalid-feedback">
            <div *ngIf="f.ciudad.errors.required">Ciudad requerido</div>
          </div>
        </div>
        <div class="col-12 col-sm-6" [formGroup]="frmEnvio" *ngIf="mostrarUnidades">
          <label class="label">Unidades</label>
          <input
            class="form-control input-login"
            formControlName="unidades"
            type="text"
            placeholder="Escribe aquí"
          />
        </div>
        <div class="col-12" [formGroup]="frmEnvio">
          <label class="label">Comentario</label>
          <label class="label-der">{{ comentario.length }}/150</label>
          <textarea
            maxlength="150"
            rows="3"
            placeholder="Mándanos un mensaje"
            class="form-control input-login"
            formControlName="comentario"
            [(ngModel)]="comentario"
            [ngClass]="{ 'is-invalid': submited && f.comentario.errors }"
          ></textarea>
          <div *ngIf="submited && f.comentario.errors" class="invalid-feedback">
            <div *ngIf="f.celular.errors.required">Comentario requerido</div>
          </div>
        </div>
        <div class="col-12 marginT-20">
          <button type="submit" class="btn-iniciar">Contactar</button>
        </div>
      </div>
    </form>
  </div>
</div>
<div [hidden]="!enviando">
  <ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    size="medium"
    color="#fff"
    type="square-jelly-box"
    [fullScreen]="true"
    ><p class="texto-blanco">Enviando...</p></ngx-spinner
  >
</div>
