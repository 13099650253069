import {BrowserModule} from '@angular/platform-browser';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareModule } from 'ngx-sharebuttons';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {AppComponent} from './app.component';
import {SharedModule} from './shared/shared.module';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from './app-routing.module';
import {HomePageComponent} from './pages/home-page/home-page.component';
import {FerreofertasComponent} from './pages/ferreofertas/ferreofertas.component';
import {ProveedoresComponent} from './pages/proveedores/proveedores.component';
import {JuegoComponent} from './pages/juego/juego.component';
import {HoroscopoComponent} from './pages/horoscopo/horoscopo.component';
import {PerfilComponent} from './pages/perfil/perfil.component';
import {TarjetaNoticiaModule} from "./pages/Components/tarjeta-noticia/tarjeta-noticia.module";
import {TarjetaHoroscopoComponent} from './pages/Components/tarjeta-horoscopo/tarjeta-horoscopo.component';
import {HoroscopoDetalleComponent} from './pages/horoscopo-detalle/horoscopo-detalle.component';
import {NoticiaDetalleComponent} from './pages/noticia-detalle/noticia-detalle.component';
import {TarjetaOfertaComponent} from './pages/Components/tarjeta-oferta/tarjeta-oferta.component';
import {DetalleOfertaComponent} from './pages/Components/detalle-oferta/detalle-oferta.component';
import {ReactiveFormsModule} from '@angular/forms';
import {FormsModule} from '@angular/forms';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import {NgxSpinnerModule} from "ngx-spinner";
import {PipesModule} from "./pipes/pipes.module";
import { TarjetaNoticiaSinimagenComponent } from './pages/Components/tarjeta-noticia-sinimagen/tarjeta-noticia-sinimagen.component';
import { TarjetaNoticiaContenidoComponent } from './pages/Components/tarjeta-noticia-contenido/tarjeta-noticia-contenido.component';
import { CrearComentarioComponent } from './pages/Components/crear-comentario/crear-comentario.component';
import { ListaComentariosComponent } from './pages/Components/lista-comentarios/lista-comentarios.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { MisDatosComponent } from './pages/perfil/mis-datos/mis-datos.component';
import { MisPuntosComponent } from './pages/perfil/mis-puntos/mis-puntos.component';
import { FavoritosComponent } from './pages/perfil/favoritos/favoritos.component';
import { RankingComponent } from './pages/perfil/ranking/ranking.component';
import { SidebarModule } from 'ng-sidebar';
import { TarjetaProveedorComponent } from './pages/Components/tarjeta-proveedor/tarjeta-proveedor.component';
import { ModalBienvenidaComponent } from './pages/Components/modal-bienvenida/modal-bienvenida.component';
import { ModalContactoComponent } from './pages/Components/modal-contacto/modal-contacto.component';
import { ModalCambiarClaveComponent } from './pages/Components/modal-cambiar-clave/modal-cambiar-clave.component';
import {CookieService} from 'ngx-cookie-service';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { YoaprendoComponent } from './pages/yoaprendo/yoaprendo.component';
import { TarjetaVideoComponent } from './pages/Components/tarjeta-video/tarjeta-video.component';
import { YoaprendoDetalleComponent } from './pages/yoaprendo-detalle/yoaprendo-detalle.component';
import { CrearComentarioVideoComponent } from './pages/Components/crear-comentario-video/crear-comentario-video.component';
import { TarjetaVideoSinimagenComponent } from './pages/Components/tarjeta-video-sinimagen/tarjeta-video-sinimagen.component';
import { PageTerminosCondicionesComponent } from './pages/page-terminos-condiciones/page-terminos-condiciones.component';
import { DeleteAccountComponent } from './pages/delete-account/delete-account.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    FerreofertasComponent,
    ProveedoresComponent,
    JuegoComponent,
    HoroscopoComponent,
    PerfilComponent,
    TarjetaHoroscopoComponent,
    HoroscopoDetalleComponent,
    NoticiaDetalleComponent,
    TarjetaOfertaComponent,
    DetalleOfertaComponent,
    TarjetaNoticiaSinimagenComponent,
    TarjetaNoticiaContenidoComponent,
    CrearComentarioComponent,
    ListaComentariosComponent,
    MisDatosComponent,
    MisPuntosComponent,
    FavoritosComponent,
    RankingComponent,
    TarjetaProveedorComponent,
    ModalBienvenidaComponent,
    ModalContactoComponent,
    ModalCambiarClaveComponent,
    YoaprendoComponent,
    TarjetaVideoComponent,
    YoaprendoDetalleComponent,
    CrearComentarioVideoComponent,
    TarjetaVideoSinimagenComponent,
    PageTerminosCondicionesComponent,
    DeleteAccountComponent,
  ],

  imports: [
    AppRoutingModule,
    HttpClientModule,
    ShareButtonsModule,
    SharedModule,
    ShareModule,
    RouterModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    TarjetaNoticiaModule,
    PipesModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    IvyCarouselModule,
    NgxSpinnerModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    SidebarModule.forRoot(),
    AutocompleteLibModule
  ],

  providers: [
    CookieService, {
          provide: HTTP_INTERCEPTORS,
          useClass: TokenInterceptor,
          multi: true,
        },
        //{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
    exports: [

    ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
