<div class="fondo-modal" *ngIf="cargado" ></div>
<div class="div-modal" *ngIf="cargado">
  <div class="row">
    <div class="col-12">
      <label class="titulo-modal float-r cursor" (click)="quitar()" >X</label>
    </div>
    <div class="col-12">
      <img id="imagenBanner" #imagenBanner class="img-fluid imagen" [src]="banner.imagen.url" />
    </div>
  </div>
</div>

