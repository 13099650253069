import { Component, PLATFORM_ID, Inject, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { AuthService } from './services/auth.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'YO FERRETERO :: La Aplicación Del Ferretero ::';
  /** Modal de bienvenida */
  mostrandoBienvenida: boolean = false;
  mostrandoNavBa: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private router: Router,
    private titleSeo: Title,
    private meta: Meta,
    private authService: AuthService
  ) {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      if (isPlatformBrowser(this.platformId)) {
        this.validationJwt();
        window.scrollTo(0, 0);
      }
    });
  }

  ngOnInit() {
    const URLactual = window.location;
    console.log("URLactual", URLactual.pathname);
    if( URLactual.pathname === '/terminos-y-condiciones' || URLactual.pathname === '/eliminar-mi-cuenta-en-plataforma'){
      this.mostrandoBienvenida = false;
      this.mostrandoNavBa= false;
    }else {
      this.mostrandoBienvenida = true;
      this.mostrandoNavBa= true;
      if (isPlatformBrowser(this.platformId)) {
        const banner = localStorage.getItem('bannerYFweb');
        this.mostrandoBienvenida = true;
      }
    }
    
    this.meta.addTags([
      { name: 'keywords', content: 'Yo ferretero, Ferretero, APLICACIÓN para ferreterías, YOFERRETERO, Ferretería, Guía proveedores, Herramientas, Ferretería, Ferretero millonario, Horóscopo, Horóscopo ferretero, Noticias ferretería, Noticias sector ferretero, Aplicación yoferretero, Quien quiere ser millonario ferretería' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'YoFerretero' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' },
      { name: 'googlebot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1' },
      { name: 'bingbot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1' },
    ], true);
  }

  /**
   * Function to validate if JWT is expired
   */
  private validationJwt() {
    //in this case JWT is set in local storage as tkYFweb
    const currentToken = localStorage.getItem('tkYFweb');
    if (currentToken) this.authService.validateJwtExpired(currentToken).subscribe((res: any) => {
      console.log(res)
      if (!res.success) {
        let { newToken, user } = res;
        this.authService.storeUserData(newToken, user)
      }
    });
  }
  /**
   * Cerrar modal de bienvenida
   */
  onSalirBienvenida(even) {
    this.mostrandoBienvenida = false;
  }
}
