<div class="container">
    <div class="row">
      <div class="col-12">
        <img class="logo-header " src="/assets/img/logo-header.png" />
      </div>
      <div class="col-12 padTextHome">
        <h4>Eliminar mi cuenta en plataforma</h4>
      </div>
    </div>
    <form [formGroup]="frmLogin" novalidate>
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-3">

                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-12">
                                <label class="texto-iniciar">Para eliminar tu cuenta de nuestra plataforma por favor diligencia los siguientes datos.

                                </label>
                              </div>
                              <div class="col-12 marginFrm">
                        
                                <label class="label-control texto-label">Tu nombre
                                </label>
                                <input
                                  class="form-control input-login"
                                  formControlName="nombre"
                                  [ngClass]="{ 'is-invalid': submited && f.nombre.errors }"
                                  type="text"
                                  placeholder="Escribe aquí tu nombre"
                                />
                                <div *ngIf="submited && f.nombre.errors" class="invalid-feedback">
                                  <div *ngIf="f.nombre.errors.required">Nombre requerido</div>
                                </div>
                              </div>
                              <div class="col-12 marginFrm">
                        
                                <label class="label-control texto-label">Tus apellidos
                                </label>
                                <input
                                  class="form-control input-login"
                                  formControlName="apellido"
                                  [ngClass]="{ 'is-invalid': submited && f.apellido.errors }"
                                  type="text"
                                  placeholder="Escribe aquí tu apellido"
                                />
                                <div *ngIf="submited && f.apellido.errors" class="invalid-feedback">
                                  <div *ngIf="f.apellido.errors.required">Apellido requerido</div>
                                </div>
                              </div>
                              <div class="col-12 marginFrm">
                                <label class="label-control texto-label">Tu correo electrónico
                                </label>
                                <input
                                  class="form-control input-login"
                                  formControlName="email"
                                  [ngClass]="{ 'is-invalid': submited && f.email.errors }"
                                  type="email"
                                  placeholder="Escribe aquí tu email"
                                />
                                <div *ngIf="submited && f.email.errors" class="invalid-feedback">
                                  <div *ngIf="f.email.errors.required">Email requerido</div>
                                  <div *ngIf="f.email.errors.email">Formato invalido</div>
                                </div>
                              </div>
                              <div class="col-12 marginFrm">
                                <label class="label-control texto-label">Tu teléfono
                                </label>
                                <input
                                  class="form-control input-login"
                                  formControlName="telefono"
                                  [ngClass]="{ 'is-invalid': submited && f.telefono.errors }"
                                  type="text"
                                  placeholder="Escribe aquí tu telefono"
                                />
                                <div *ngIf="submited && f.telefono.errors" class="invalid-feedback">
                                  <div *ngIf="f.telefono.errors.required">Email requerido</div>
                                </div>
                              </div>
                              <div class="col-12 marginFrm">
                                <label class="label-control texto-label">Tu mensaje (Opcional)
                                </label>
                                <textarea
                                  class="form-control input-login"
                                  formControlName="mensaje"
                                  [ngClass]="{ 'is-invalid': submited && f.mensaje.errors }"
                                  type="text"
                                  placeholder="Escribe aquí tu mensaje"
                                > </textarea>
                                <div *ngIf="submited && f.mensaje.errors" class="invalid-feedback">
                                  <div *ngIf="f.mensaje.errors.required">Email requerido</div>
                                </div>
                              </div>
                              <div class="col-12">
                                <button type="button" class="btn-crear" (click)="onIniciar()" >Enviar</button>
                              </div>
                              <ngx-spinner
                                bdColor="rgba(0, 0, 0, 0.1)"
                                size="medium"
                                color="#FE4A12"
                                type="square-jelly-box"
                                [fullScreen]="true"
                                ><p class="texto-verificando">Enviando...</p></ngx-spinner
                              >
                        </div>
                    </div>
                </div>
            </div>
          
        </div>
       
      </form>
  </div>
  