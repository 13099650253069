import { BannerInterface } from './../../models/banner';
import { UtilService } from 'src/app/services/util.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { NoticiasInterface } from 'src/app/models/noticias';
import { MainService } from 'src/app/services/main.service';
import { AuthService } from 'src/app/services/auth.service';
import * as _ from 'lodash';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { UsuarioInterface } from 'src/app/models/user';
import { BehaviorSubject } from 'rxjs';
declare interface OfertasImagenes {
  imagen: string;
}

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  /** Verifica si el usuario esta logueado */
  usuarioLogueado = false;
  /** Arreglo que almacena la lista de noticias */
  listaNoticias: NoticiasInterface[] = [];
  /** Arreglo que almacena la lista de noticias */
  listaNoticiasDestacadas: NoticiasInterface[] = [];
  /** Objeto donde se alamacenara la noticia principal */
  noticiaPrincipal: any;
  /** Variable de control para determinar que los datos fueron cargados */
  cargaCompleta = false;
  /** Banners de las noticas */
  bannerSuperior: BannerInterface;
  bannerInferior: BannerInterface;
  /** Objeto de noticias desde localStorage */
  storeNoticias: any;
   /** usuario activo */
   usuario: UsuarioInterface;
   /** usuario local storage */
   usuarioLocal: UsuarioInterface;
   usuarioCargado=false;
   /** Emisor para detalle de OFerta */
  emisorUsuario = new BehaviorSubject({});
  /** variable de control para cantidad de noticias a mostrar */
  cantNoticias = 10;
  /** Mostrar ver mas */
  mostrarVerMas = true;
  /** Cantidad de noticias */
  totalNoticias= 0;
  /** Contadores de ver mas */
  contVermas = 1;
  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private title: Title, private meta: Meta,
    public mainService: MainService,
    public authService: AuthService,
    public router: Router,
    public utilService: UtilService
  ) {
    this.emisorUsuario.next(null);
    this.usuarioLocal = this.authService.getUser();
    this.authService.logueado.subscribe( logueado => {
      this.usuarioLogueado =  logueado;
      if (logueado) {
        this.usuarioLocal = this.authService.getUser();
        this.getUsuario();
      } else {
        this.usuarioCargado = true;
      }
    });
  }
  ngOnInit() {
    this.setSeo();
    this.utilService.startLoading();
    this.getNoticias();
  }
  setSeo() {
    let title = 'YO FERRETERO :: La Aplicación Del Ferretero ::';
    let description = 'La primera aplicación ferretera que tiene como objetivo crear una comunidad y ser una herramienta de capacitación, actualización y entretenimiento para el sector ferretero. Puedes encontrar noticias del momento, capacitación, la guía de proveedores más completa del sector, ofertas y mucho más';
    let image = this.mainService.baseURL+'assets/img/logo-login.png';
    this.title.setTitle(title);
    this.meta.addTag({ property: 'og:title', content: title });
    this.meta.addTag({ name: 'twitter:title', content: title });
    this.meta.addTag({ name: 'twitter:image:alt', content: title });
    this.meta.addTag({ property: 'og:image:alt', content: title });
    this.meta.addTag({ property: 'og:title', content: title });
    this.meta.addTag({ name: 'title', content: title });
    this.meta.addTag({ itemprop: 'name', content: title });

    this.meta.addTag({ name: 'twitter:image', content: image });
    this.meta.addTag({ itemprop: 'image', content: image });

    this.meta.addTag({ name: 'twitter:description', content: description });
    this.meta.addTag({ property: 'og:description', content: description });
    this.meta.addTag({ name: 'description', content: description });
    this.meta.addTag({ itemprop: 'description', content: description });
    this.meta.addTag( {name: 'keywords', content: 'Yo ferretero, Ferretero, APLICACIÓN para ferreterías, YOFERRETERO, Ferretería, Guía proveedores, Herramientas, Ferretería, Ferretero millonario, Horóscopo, Horóscopo ferretero, Noticias ferretería, Noticias sector ferretero, Aplicación yoferretero, Quien quiere ser millonario ferretería'})

    this.meta.addTag({ property: 'og:image', itemprop:"image",content: image });
    this.meta.addTag({ property: 'og:type', content: 'website' });
    this.meta.addTag({ property: 'twitter:card', content: 'summary_large_image' });
    this.meta.addTag({ property: 'og:image:width', content: '300' });
    this.meta.addTag({ property: 'og:image:height', content: '200' });
    this.meta.addTag({ property: 'og:url', content: 'https://www.yoferretero.com/' });
    this.meta.addTag({ itemprop:"thumbnailUrl",content: image });
  }
  /**
   * Obtiene lista de noticias publicadas se limita a 250 registros
   */
  getNoticias() {
    let now = new Date();
    if (isPlatformBrowser(this.platformId)) {
      this.mainService
        .get('api/noticiaPublicadasApp/' + now)
        .subscribe((noticias: NoticiasInterface[]) => {
          this.utilService.storeNoticias(noticias);
            this.listaNoticias = noticias;
            let principal = this.listaNoticias.splice(0, 1);
            this.totalNoticias = this.listaNoticias.length;
            this.noticiaPrincipal = principal[0];
            let destacadas = this.listaNoticias.splice(0, 3);
            this.listaNoticiasDestacadas = destacadas;
          this.getBanners();
        });
    }
  }
  /**
   * Metodo para incrementar la cantidad de noticias a ver
   */
   vermas() {
    this.contVermas = this.contVermas+1;
    let van = this.cantNoticias * this.contVermas;
    if (van >= this.totalNoticias){
      this.mostrarVerMas = false;
    }
  }
  /**
   * Obtener banners de noticias
   */
  getBanners() {
    if (isPlatformBrowser(this.platformId)) {
      this.mainService
        .get('api/banner')
        .subscribe((banners: BannerInterface[]) => {
          for (let item of banners) {
            if (item.nombre === 'Superior web') {
              this.bannerSuperior = item;
            }
            if (item.nombre === 'Inferior web') {
              this.bannerInferior = item;
            }
          }
          if (!this.cargaCompleta) {
            this.cargaCompleta = true;
            this.utilService.stopLoading();
          }

        });
    }
  }
  /**
   * Oculta el cargando despues que la noticia fue modificada
   * @param event respuesta del evento de noticia modificada
   */
  onNoticiaModificada(event) {
    this.getUsuario();
    this.utilService.stopLoading();
  }
  /**
   * Muestra loading cuando inicia proceso de modificacion de alguna noticia
   * @param event respuesta del evento cuando se esta modificando la noticia
   */
  onModificando(event) {
    if (event) {
      this.utilService.startLoading();
    }
  }
  /**
   * Obtiene el usuario activo para verificar si la noticia fue guardada o le dieron like
   */
  getUsuario() {
    this.mainService
      .get("api/user/" + this.usuarioLocal._id)
      .subscribe( (user: UsuarioInterface) => {
        this.emisorUsuario.next(user);
        this.usuario = user;
        this.usuarioCargado = true;
      });
  }

}
