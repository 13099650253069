import { AudioService } from './../../services/audio.service';
import { UtilService } from './../../services/util.service';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { PreguntaInterface } from './../../models/pregunta';
import { MainService } from 'src/app/services/main.service';
import { PuntosInterface } from 'src/app/models/puntos';
import { timer } from 'rxjs';
import { UsuarioInterface } from 'src/app/models/user';
import { BannerInterface } from 'src/app/models/banner';
import * as _ from 'lodash';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'juego',
  templateUrl: './juego.component.html',
  styleUrls: ['./juego.component.scss'],
})
export class JuegoComponent implements OnInit {
  /** Boleano para verificar si se cumplio cuota del dia */
  cuotaDiaria = false;
  /** Puntos */
  puntos = 0;
  /** Usuario desde localStorage */
  usuario: UsuarioInterface;
  /** Usuario desde base de datos */
  user: UsuarioInterface;
  /** arreglo para las letras de las opciones */
  letras = ['A:', 'B:', 'C:', 'D:'];
  /** Mapa de puntaje de preguntas */
  preguntas = [
    '200 puntos',
    '140 puntos',
    '130 puntos',
    '120 puntos',
    '110 puntos',
    '100 puntos',
    '90 puntos',
    '80 puntos',
    '70 puntos',
    '60 puntos',
    '50 puntos',
    '40 puntos',
    '30 puntos',
    '20 puntos',
    '10 puntos',
  ];
  /** Abrir vistas del juego */
  AbrirJuego: boolean = false;
  /** Iniciar Juego */
  iniciarJuego: boolean = false;
  /** Opción correcta en caso de que la persona la tenga mal */
  opcionCorrecta: number = -1;
  /** Bandera para controlar pausa de juego entre preguntas */
  juegoEnPausa: boolean = true;
  /** Listado de preguntas para el usuario */
  preguntasUsuario: PreguntaInterface[];
  /** Objeto con la pregunta activa durante el juego */
  preguntaActiva: PreguntaInterface;
  /** Indica en cual pregunta va */
  progreso: number = 0;
  /** Contador de tiempo de pregunta */
  tiempoPregunta: number = 30;
  /** bandera para controlar carga de datos de las preguntas */
  cargaCompleta = false;
  /** Para contador de tiempo pro pregunta */
  _second = 1000;
  _minute = this._second * 60;
  _hour = this._minute * 60;
  _day = this._hour * 24;
  end: any;
  now: any;
  seconds: any;
  source = timer(0, 1000);
  clock: any;
  /** verifica respuesta */
  respuestaCorrecta = false;
  respuestaIncorrecta = false;
  opcionSeleccionada = -1;
  /** Para controlar el mostrar el modal de mensajes */
  modalMensajes = false;
  /** Para controlar el mostrar el mensaje de error */
  msgError = false;
  msgErrorAgotado = false;
  /** Para controlar el mostrar el mensaje de error */
  msgCorrecto = false;
  /** Comodin activo */
  comodinSeleccionado: number = -1; // 0: 50/50    1: llamada     2: ferreteros
  /** Descripcion del comodin seleccionado y verificacion de si fue usado */
  descripComodin = [
    {
      texto: 'Con esta ayuda se eliminarán dos de las respuestas incorrectas',
      img: '/assets/img/juego/ayuda-50-50.png',
      titulo: '50/50',
      usado: false,
    },
    {
      texto:
        'Con esta ayuda recibirás una sugerencia de cual podría ser la respuesta correcta. Sigue así',
      img: '/assets/img/juego/ayuda-llamar-vecino.png',
      titulo: 'Llamada',
      usado: false,
    },
    {
      texto:
        'Has elegido la ayuda de los ferreteros, con esta ayuda sabrás que respondería la audiencia en tu caso.',
      img: '/assets/img/juego/ayuda-ferreteros.png',
      titulo: 'Ferreteros',
      usado: false,
    },
  ];
  /** Opcion recomendada por comodin */
  opcionRecomendada = -1;
  /** Opciones a ocultar en el comodin 50/50 */
  opcionesOcultar = [];
  /** Escalor de seguridad para mostrar ventanas de mensajes */
  escalonSeguro = false;
  /** Nivel de dificultad de las preguntas a buscar */
  nivelActual = 1;
  /** Carga de preguntas de nivel */
  cargandoPregunta = true;
  /** Mostrar y ocular mensaje al finalizar el juego */
  finalizandoJuego = false;
  /** Controla el proceso de calculo de puntos al finalizar la partida */
  calculando = false;
  /** Objeto con información del banner a mostrar al finalizar el juego */
  banner: BannerInterface;
  /** Lista de preguntas nivel1 */
  preguntasNivel1 = [];
  /** Lista de preguntas nivel2 */
  preguntasNivel2 = [];
  /** Lista de preguntas nivel3 */
  preguntasNivel3 = [];
  /** Sonado fondo */
  sonandoFondo = false;
  usuarioLogueado = false;
  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private title: Title,
    private meta: Meta,
    private mainService: MainService,
    public authService: AuthService,
    public utilService: UtilService,
    public audioService: AudioService
  ) {
    this.usuario = this.authService.getUser();
    this.authService.logueado.subscribe((logueado) => {
      this.usuarioLogueado = logueado;
      if (logueado) {
        this.usuario = this.authService.getUser();
      }
    });
  }

  ngOnInit() {
    this.getBanner();
    this.setSeo(
      'YO FERRETERO :: La Aplicación Del Ferretero ::FerreteroMillonario',
      'Juego que permite al usuario divertirse, ganar premios y a la vez aprender del sector',
      this.mainService.baseURL + 'assets/img/logo-login.png'
    );
  }
  /**
   * Establece los patametros del SEO para la oferta
   */
  setSeo(title, description, image) {
    this.title.setTitle(title);
    this.meta.addTag({ property: 'og:title', content: '' }, true);
    this.meta.addTag({ name: 'twitter:title', content: title });
    this.meta.addTag({ name: 'twitter:image:alt', content: title });
    this.meta.addTag({ property: 'og:image:alt', content: title });
    this.meta.addTag({ property: 'og:title', content: title });
    this.meta.addTag({ name: 'title', content: title });
    this.meta.addTag({ itemprop: 'name', content: title });

    this.meta.addTag({ name: 'twitter:image', content: image });
    this.meta.addTag({ itemprop: 'image', content: image });
    this.meta.addTag({ property: 'og:image', content: image });

    this.meta.addTag({ name: 'twitter:description', content: description });
    this.meta.addTag({ property: 'og:description', content: description });
    this.meta.addTag({ name: 'description', content: description });
    this.meta.addTag({ itemprop: 'description', content: description });

    this.meta.addTag({
      property: 'og:image',
      itemprop: 'image',
      content: image,
    });
    this.meta.addTag({ property: 'og:type', content: 'website' });
    this.meta.addTag({
      property: 'twitter:card',
      content: 'summary_large_image',
    });
    this.meta.addTag({ property: 'og:image:width', content: '300' });
    this.meta.addTag({ property: 'og:image:height', content: '200' });
    this.meta.addTag({
      property: 'og:url',
      content: 'https://www.yoferretero.com/juego',
    });
  }
  /**
   * Obtiene todos los datos del usuario
   */
  getUsuario() {
    if (isPlatformBrowser(this.platformId)) {
      this.mainService.get('api/user/' + this.usuario._id).subscribe((user) => {
        this.user = user;
        this.preguntasNivel1 = [];
        this.preguntasNivel2 = [];
        this.preguntasNivel3 = [];
        if (this.user.preguntas !== undefined && this.user.preguntas !== null) {
          if (
            this.user.preguntas.nivel1 !== undefined &&
            this.user.preguntas.nivel1 !== null
          ) {
            this.preguntasNivel1 = this.user.preguntas.nivel1;
          }
          if (
            this.user.preguntas.nivel2 !== undefined &&
            this.user.preguntas.nivel2 !== null
          ) {
            this.preguntasNivel2 = this.user.preguntas.nivel2;
          }
          if (
            this.user.preguntas.nivel3 !== undefined &&
            this.user.preguntas.nivel3 !== null
          ) {
            this.preguntasNivel3 = this.user.preguntas.nivel3;
          }
        }
        this.getPreguntasUsuario();
      });
    }
  }
  /**
   * Obtener banner del juego
   */
  getBanner() {
    if (isPlatformBrowser(this.platformId)) {
      this.mainService
        .get('api/banner-name/Juego')
        .subscribe((banner: BannerInterface) => {
          this.banner = banner;
        });
    }
  }
  /**
   * Obtiene listado de preguntas activas para el usuario dependiendo del nivel
   */
  getPreguntasUsuario() {
    if (isPlatformBrowser(this.platformId)) {
      this.mainService
        .get('api/preguntaUsuario/' + this.usuario._id + '/' + this.nivelActual)
        .subscribe((resp: any) => {
          this.preguntasUsuario = [];
          let self = this;
          switch (this.nivelActual) {
            case 1:
              this.preguntasUsuario = _.filter(resp, function (o) {
                for (let pregunta of self.preguntasNivel1) {
                  if (o._id === pregunta) {
                    return false;
                  }
                }
                return true;
              });
              break;
            case 2:
              this.preguntasUsuario = _.filter(resp, function (o) {
                for (let pregunta of self.preguntasNivel2) {
                  if (o._id === pregunta) {
                    return false;
                  }
                }
                return true;
              });
              break;
            case 3:
              this.preguntasUsuario = _.filter(resp, function (o) {
                for (let pregunta of self.preguntasNivel3) {
                  if (o._id === pregunta) {
                    return false;
                  }
                }
                return true;
              });
              break;
          }
          if (this.preguntasUsuario.length <= 10) {
            switch (this.nivelActual) {
              case 1:
                this.preguntasNivel1 = [];
                break;
              case 2:
                this.preguntasNivel2 = [];
                break;
              case 3:
                this.preguntasNivel3 = [];
                break;
            }
          }
          this.cargaCompleta = true;
          this.utilService.stopLoading();
          this.AbrirJuego = true;
          if (this.nivelActual > 1) {
            let self = this;
            setTimeout(function () {
              self.escalonSeguro = false;
            }, 5000);
          }
        });
    }
  }
  /**
   * Metodo para guardar la visita del usuario
   */
  guardarVisita() {
    let visita = {
      plataforma: 'web',
      usuario: this.usuario._id,
      modulo: 'juego',
    };
    this.utilService.guardarVisita(visita);
  }

  onVolver() {
    if (isPlatformBrowser(this.platformId)) {
      window.location.reload();
    }
  }

  onJugar() {
    if (this.usuarioLogueado) {
      this.utilService.startLoading();
      this.getUsuario();
      this.verificarCuotaDiaria();
      if (isPlatformBrowser(this.platformId)) {
        this.audioService.play('apertura');
      }
      this.guardarVisita();
    } else {
      this.utilService.showWarning({
        msj: 'Debe iniciar sesión para jugar Ferretero Millonario',
        title: 'Alerta',
        time: 5000,
      });
    }
  }

  /**
   * Inicia proceso del juego
   */
  onIniciar() {
    if (isPlatformBrowser(this.platformId)) {
      this.audioService.playFondo();
    }
    this.sonandoFondo = true;
    this.obtenerPreguntaAleatoria();
    this.iniciarJuego = true;
    this.juegoEnPausa = false;
    this.iniciarCuenta();
  }

  /**
   * pasar a siguiente pregunta
   */
  onSiguientePregunta() {
    this.progreso++;
    this.juegoEnPausa = false;
    this.obtenerPreguntaAleatoria();
    this.respuestaCorrecta = false;
    this.iniciarCuenta();
  }

  /**
   * Obtener pregunta aleatoria del listado de preguntas disponibles para el usuario en el nivel activo
   */
  obtenerPreguntaAleatoria() {
    let pos = this.getRandomOption({
      min: 0,
      max: this.preguntasUsuario.length,
    });
    let pregunta = this.preguntasUsuario.splice(pos, 1);
    this.preguntaActiva = pregunta[0];
    this.marcarPregunta();
  }

  /**
   * Marca la pregunta como respondida por el usuario para que no le vuelva a aparecer
   */
  marcarPregunta() {
    switch (this.nivelActual) {
      case 1:
        this.preguntasNivel1.push(this.preguntaActiva._id);
        break;
      case 2:
        this.preguntasNivel2.push(this.preguntaActiva._id);
        break;
      case 3:
        this.preguntasNivel3.push(this.preguntaActiva._id);
        break;
    }
  }

  /**
   * Funcion para evaluar la respuesta seleccionada por el usuario
   * @param item Es la opcion seleccionada por el usuario
   */
  seleccionaRespuesta({ item, opcion }) {
    this.opcionSeleccionada = opcion;
    this.clock.unsubscribe();
    if (item.correcta === true) {
      this.ejecutarCorrecta();
    } else {
      this.mostrarCorrecta();
      setTimeout(() => {
        this.ejecutarIncorrecta({ tipo: "seleccionada" });
      }, 2500)
    }
  }

  /**
   * Muestra la opción correcta si la persona elige una incorrecta.
   */
  mostrarCorrecta(){
    for(let opcion of this.preguntaActiva.opciones){
      console.log(opcion);
      if(opcion.correcta){
        let indexc = this.preguntaActiva.opciones.indexOf(opcion);
        this.opcionCorrecta = indexc;
      }
    }
  }

  /**
   * Funcion para evaluar la respuesta seleccionada por el usuario
   * @param item Es la opcion seleccionada por el usuario
   */
  tiempoAgotado() {
    this.ejecutarIncorrecta({ tipo: 'tiempo' });
  }

  /**
   * Ejecuta eventos para la respuesta incorrecta
   * @param tipo Determina cual tipo de respuesta incorrecta se ejecuta si por mala seleccion o por tiempo agotado
   */
  ejecutarIncorrecta({ tipo }) {
    if (isPlatformBrowser(this.platformId)) {
      this.audioService.play('perdio');
    }
    this.respuestaIncorrecta = true;
    this.modalMensajes = true;
    if (tipo === 'seleccionada') {
      this.msgError = true;
    } else {
      this.msgErrorAgotado = true;
    }
  }

  /**
   * Funcion que se ejecuta cuando la respuesta es correcta
   */
  ejecutarCorrecta() {
    this.respuestaCorrecta = true;
    this.juegoEnPausa = true;
    this.opcionSeleccionada = -1;
    this.opcionRecomendada = -1;
    this.opcionesOcultar = [];
    this.tiempoPregunta = 30;
    if (this.progreso === 4 || this.progreso === 9 || this.progreso === 14) {
      if (isPlatformBrowser(this.platformId)) {
        this.audioService.play('nivel');
      }
      this.cargandoPregunta = false;
      if (this.progreso === 4) {
        this.nivelActual = 2;
        this.getPreguntasUsuario();
      }
      if (this.progreso === 9) {
        this.nivelActual = 3;
        this.getPreguntasUsuario();
      }
      this.escalonSeguro = true;
      if (this.progreso === 14) {
        this.finalizarJuego({ tipo: 'gano' });
      } else {
        let self = this;
        setTimeout(function () {
          self.escalonSeguro = false;
        }, 5000);
      }
    } else {
      if (isPlatformBrowser(this.platformId)) {
        this.audioService.play('correcta');
      }
    }
  }

  /**
   * Iniciar cuenta de regresiva por pregunta
   */
  iniciarCuenta() {
    this.now = new Date();
    this.end = new Date().setSeconds(
      this.now.getSeconds() + this.tiempoPregunta
    );
    this.clock = this.source.subscribe((t) => {
      this.showDate();
    });
  }

  /**
   * Asignacion del tiempo restante se ejecuta cada segundo
   * Al finalizar la cuenta, se ejecuta la funcion del tiempoAgotado
   */
  showDate() {
    let now = new Date();
    //@ts-ignore
    let distance = this.end - now;
    this.seconds = Math.floor((distance % this._minute) / this._second);
    if (this.seconds === 0) {
      this.clock.unsubscribe();
      this.tiempoAgotado();
    }
  }

  /**
   * Cerrar modal cuando pregunta es incorrecta
   */
  cerrarError() {
    this.modalMensajes = false;
    this.msgError = false;
    this.juegoEnPausa = true;
    this.iniciarJuego = false;
    this.finalizarJuego({ tipo: 'perdio' });
  }

  /**
   * Reiniciar las variables de control de juego como progreso y resppuesta seleccionada
   */
  reiniciarJuego() {
    this.onVolver();
  }

  /**
   * Reiniciar las variables de control de juego para siguiente pregunta
   */
  reiniciarPregunta() {
    this.opcionCorrecta = -1;
    this.opcionSeleccionada = -1;
    this.opcionRecomendada = -1;
    this.opcionesOcultar = [];
    this.tiempoPregunta = 30;
  }

  /**
   * Inicia efecto y mensaje informativo del comodin a utilizar y pone pausa al tiempo para la pregunta
   * @param comodin selecionado por el usuario
   */
  seleccionarComodin({ comodin }) {
    this.detenerTiempoComodin();
    this.comodinSeleccionado = comodin;
    this.modalMensajes = true;
    if (this.comodinSeleccionado === 0) {
      if (isPlatformBrowser(this.platformId)) {
        this.audioService.play('50-50');
      }
    }
    if (this.comodinSeleccionado === 1) {
      if (isPlatformBrowser(this.platformId)) {
        this.audioService.play('llamada');
      }
    }
    if (this.comodinSeleccionado === 2) {
      if (isPlatformBrowser(this.platformId)) {
        this.audioService.play('publico');
      }
    }
  }

  /**
   * Ejecuta la accion a realizar dependiendo del comodin seleccionado
   */
  ejecutarComodin() {
    this.modalMensajes = false;
    this.descripComodin[this.comodinSeleccionado].usado = true;
    if (this.comodinSeleccionado !== 0) {
      this.opcionRecomendada = this.optenerRecomendacion();
    } else {
      let incorrectas = [];
      let i = 0;
      for (let opt of this.preguntaActiva.opciones) {
        if (!opt.correcta) {
          incorrectas.push(i);
        }
        i++;
      }
      let pos = this.getRandomOption({ min: 0, max: 3 });
      incorrectas.splice(pos, 1);
      this.opcionesOcultar = incorrectas;
    }
    this.comodinSeleccionado = -1;
    this.iniciarCuenta();
  }

  /**
   * Optiene respuesta correcta como recomendacion
   */
  optenerRecomendacion() {
    let seleccionar = -1;
    let i = 0;
    let ramdon10 = this.getRandomOption({ min: 1, max: 10 });
    if (ramdon10 !== 1) {
      for (let opt of this.preguntaActiva.opciones) {
        if (opt.correcta) {
          seleccionar = i;
          break;
        }
        i++;
      }
    } else {
      seleccionar = this.getRandomOption({ min: 0, max: 4 });
    }

    return seleccionar;
  }

  /**
   * Optiene item a mostrar de las respuesta inconrectas
   * @param param0 valores min y max de opciones a eliminar con el comodin 50/50
   */
  getRandomOption({ min, max }) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  /**
   * Poner pausa al tiempo mientras se ejecuta el comodin
   */
  detenerTiempoComodin() {
    this.tiempoPregunta = this.seconds;
    this.clock.unsubscribe();
  }

  /**
   * Funcion que se ejecuta al finalizar el juego
   * @param param0 Tipo de final si fue ganado o perdio
   */
  finalizarJuego({ tipo }) {
    if (isPlatformBrowser(this.platformId)) {
      this.audioService.stopSound('fondo');
      this.audioService.play('gano');
    }
    this.calculando = true;
    this.calcularPuntos({ tipo: tipo });
    this.modificarUsuario();
    switch (tipo) {
      case 'gano':
        let self = this;
        setTimeout(function () {
          self.escalonSeguro = false;
          self.finalizandoJuego = true;
        }, 5000);
        break;
      case 'perdio':
        this.finalizandoJuego = true;
        break;
    }
  }

  calcularPuntos({ tipo }) {
    let puntos = 0;
    switch (tipo) {
      case 'gano':
        puntos = 200;
        break;
      case 'perdio':
        if (this.nivelActual === 2) {
          puntos = 50;
        }
        if (this.nivelActual === 3) {
          puntos = 100;
        }
        break;
    }
    this.puntos = puntos;
    if (puntos > 0) {
      let puntuacion: PuntosInterface = {
        evento: 'Jugar Ferretero Millonario',
        referencia: this.usuario._id,
        usuario: this.usuario._id,
        puntos: puntos,
      };
      this.guardarHistorialDePuntos(puntuacion);
    } else {
      this.calculando = false;
    }
  }

  /**
   * Metodo para guardar el eventos y los puntos del usuario
   * @param puntuacion objeto de puntuacion para el historia de puntos del usuario
   */
  guardarHistorialDePuntos(puntuacion: PuntosInterface) {
    this.mainService.post('api/puntos', puntuacion).subscribe((result) => {
      console.log('Guardo historial de puntos');
      this.calculando = false;
    });
  }
  /**
   * Modificar preguntas del usuario
   */
  modificarUsuario() {
    this.user.preguntas = {
      nivel1: this.preguntasNivel1,
      nivel2: this.preguntasNivel2,
      nivel3: this.preguntasNivel3,
    };
    this.mainService
      .put('api/user/' + this.usuario._id, this.user)
      .subscribe((result: any) => {});
  }
  /**
   * Verificar puntos acumulados en el dia
   */
  verificarCuotaDiaria() {
    if (isPlatformBrowser(this.platformId)) {
      this.mainService
        .get('api/resumenDiario/' + this.usuario._id)
        .subscribe((data: any) => {
          if (data.success) {
            let acumulado = 0;
            if (data.data.length > 0) {
              for (let item of data.data) {
                if (item.evento === 'Jugar Ferretero Millonario') {
                  acumulado = item.puntos;
                  break;
                }
              }
            }
            if (acumulado >= 1000) {
              this.cuotaDiaria = true;
            }
          }
          console.log(this.cuotaDiaria);
        });
    }
  }
}
