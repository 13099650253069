<div class="fondo-modal"></div>
<div class="div-modal">
  <div class="row">
    <div class="col-12">
      <label class="titulo-modal float-r cursor" (click)="salir()" >X</label>
    </div>
    <div class="col-sm-4 col-3"></div>
    <div class="col-sm-4 col-6">
      <img class="img-fluid" src="/assets/img/logo-login.png" />
    </div>
  </div>
  <form [formGroup]="frmOlvide" (ngSubmit)="onEnviar()" novalidate>
    <div class="row">
      <div class="col-12">
        <label class="texto-iniciar">Cambiar contraseña</label>
      </div>
      <div class="col-12">
        <label class="label-control texto-label">Contraseña anterior</label>
        <input
          class="form-control input-login"
          formControlName="password"
          [ngClass]="{ 'is-invalid': submited && f.password.errors }"
          [type]="tipoInput"
          placeholder="Escribe aquí"
        />
        <i
          *ngIf="!mostrar"
          (click)="toogleIcono()"
          class="fa fa-eye icono-password"
        ></i>
        <i
          *ngIf="mostrar"
          (click)="toogleIcono()"
          class="fa fa-eye-slash icono-password"
        ></i>
        <div *ngIf="submited && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">Contraseña requerida</div>
        </div>
      </div>
      <div class="col-12">
        <label class="control-label texto-label">Contraseña nueva</label>
        <input
          [type]="tipoInputNew"
          placeholder="Escribe aquí tu contraseña"
          class="form-control  input-login"
          formControlName="passwordNueva"
          [ngClass]="{ 'is-invalid': submited && f.passwordNueva.errors }"
        />
        <i
          *ngIf="!mostrarNew"
          (click)="toogleIcono2()"
          class="fa fa-eye icono-password"
        ></i>
        <i
          *ngIf="mostrarNew"
          (click)="toogleIcono2()"
          class="fa fa-eye-slash icono-password"
        ></i>
        <div *ngIf="submited && f.passwordNueva.errors" class="invalid-feedback">
          <div *ngIf="f.passwordNueva.errors.required">Password requerido</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6"></div>
      <div class="col-12 col-sm-12">
        <button type="submit" class="btn-iniciar">Cambiar</button>
      </div>
    </div>
    <ngx-spinner
      bdColor="rgba(0, 0, 0, 0.1)"
      size="medium"
      color="#fff"
      type="square-jelly-box"
      [fullScreen]="true"
      ><p class="texto-blanco">Espere por favor...</p></ngx-spinner
    >
  </form>
</div>

