<main role="main" class="fondo">
  <ngx-spinner
    bdColor="rgba(0, 0, 0, 0.1)"
    size="medium"
    color="#fff"
    type="square-jelly-box"
    [fullScreen]="true"
    ><p class="texto-blanco">Espere por favor...</p></ngx-spinner
  >
  <div class="container mx-auto marginT-20" *ngIf="cargaCompleta">
    <p class="menu d-lg-none" (click)="toggleSidebar()" ><span>Menu</span></p>
    <ng-sidebar-container class="sidebar-container">
      <ng-sidebar [(opened)]="opened" mode="slide" [dockedSize]="'50px'">
        <div class="header-menu">
          <label>Mi perfil</label>
        </div>
        <div
          [ngClass]="{
            activo: panelActivo === 1,
            'item-menu': panelActivo !== 1
          }"
          (click)="seleccionarTab(1)"
        >
          <i class="fa fa-user"></i>Mis datos
        </div>

        <div
          [ngClass]="{
            activo: panelActivo === 2,
            'item-menu': panelActivo !== 2
          }"
          (click)="seleccionarTab(2)"
        >
          <i class="fa fa-user-plus"></i>Mis puntos
        </div>

        <div
          [ngClass]="{
            activo: panelActivo === 3,
            'item-menu': panelActivo !== 3
          }"
          (click)="seleccionarTab(3)"
        >
          <i class="fa fa-archive"></i>Guardados
        </div>

        <div
          [ngClass]="{
            activo: panelActivo === 4,
            'item-menu': panelActivo !== 4
          }"
          (click)="seleccionarTab(4)"
        >
          <i class="fa fa-star"></i>Ranking
        </div>
        <hr />
        <div (click)="onLogout()" class="item-menu oscuro">
          <i class="fa fa-sign-out"></i> Cerrar sesión
        </div>
      </ng-sidebar>
      <!-- Page content -->
      <div ng-sidebar-content class="content-side-bar">
        <div [hidden]="panelActivo !== 1">
          <app-mis-datos
            [usuario]="emisorUsuario"
            [puntos]="emisorPuntos"
            (modificado)="onActualizarUsuario($event)"
            (cambiarClave)="onCambioDeClave($event)"
          ></app-mis-datos>
        </div>

        <div [hidden]="panelActivo !== 2">
          <app-mis-puntos
            [userObser]="emisorUsuario"
            (puntos)="onPuntosMes($event)"
          ></app-mis-puntos>
        </div>

        <div [hidden]="panelActivo !== 3">
          <app-favoritos
            [noticias]="emisorNoticias"
            [ofertas]="emisorOfertas"
            [videos]="emisorVideos"
            (actualizar)="onActualizarUsuario($event)"
            (actualizando)="onActualizando($event)"
          ></app-favoritos>
        </div>

        <div [hidden]="panelActivo !== 4">
          <app-ranking></app-ranking>
        </div>
      </div>
    </ng-sidebar-container>
  </div>
  <div *ngIf="cambiandoClave">
    <modal-cambiar-clave (cerrar)="onCambioDeClave($event)"></modal-cambiar-clave>
  </div>
  <div class="fondo-modal" [hidden]="!modalPuntos"></div>
  <div class="div-modal" [hidden]="!modalPuntos">
    <div class="row">
      <div class="col-10">
        <label class="titulo-modal">¿Cómo acumular puntos?</label>
      </div>
      <div class="col-2">
        <label class="titulo-modal float-r cursor" (click)="modalPuntos = false"
          >X</label
        >
      </div>
    </div>
    <div class="row ali-vertical">
      <div class="col-2">
        <i class="fa fa-thumbs-up iconos"></i>
      </div>
      <div class="col-10">
        <p class="texto-modal">
          1. <b>Dale like</b> a las noticias o ferreofertas: 5 puntos
        </p>
      </div>
    </div>
    <div class="row ali-vertical">
      <div class="col-2">
        <i class="fa fa-commenting iconos"></i>
      </div>
      <div class="col-10">
        <p class="texto-modal">
          2. <b>Deja tu comentario en </b> las noticias: 20 puntos
        </p>
      </div>
    </div>
    <div class="row ali-vertical">
      <div class="col-2">
        <i class="fa fa-share-alt iconos"></i>
      </div>
      <div class="col-10">
        <p class="texto-modal">
          3. <b>Comparte </b> las noticias o ferreofertas: 5 puntos
        </p>
      </div>
    </div>
    <div class="row ali-vertical">
      <div class="col-2">
        <i class="fa fa-bookmark iconos"></i>
      </div>
      <div class="col-10">
        <p class="texto-modal">
          4. <b>Guarda</b> noticias o ferreofertas: 10 puntos
        </p>
      </div>
    </div>
    <div class="row ali-vertical">
      <div class="col-2">
        <i class="fa fa-envelope iconos"></i>
      </div>
      <div class="col-10">
        <p class="texto-modal">
          5. <b>Solicita una cotización</b> o contacta a un proveedor: 30 puntos
        </p>
      </div>
    </div>
    <div class="row ali-vertical">
      <div class="col-2">
        <i class="fa fa-gamepad iconos"></i>
      </div>
      <div class="col-10">
        <p class="texto-modal">
          6. <b>Juega el Ferretero Millonario:</b>  y gana hasta 1,000 puntos por día
        </p>
      </div>
    </div>
  </div>
</main>
