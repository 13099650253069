<div class="fondo-modal"></div>
<div class="div-modal">
  <div class="row">
    <div class="col-12">
      <label class="titulo-modal float-r cursor" (click)="salir()" >X</label>
    </div>
    <div class="col-sm-4 col-3"></div>
    <div class="col-sm-4 col-6">
      <img class="img-fluid" src="/assets/img/logo-login.png" />
    </div>
  </div>

  <form [formGroup]="frmLogin" (ngSubmit)="onIniciar()" novalidate>
    <div class="row">
      <div class="col-12">
        <label class="texto-iniciar">Iniciar sesión</label>
      </div>
      <div class="col-12">

        <label class="label-control texto-label">Email</label>
        <input
          class="form-control input-login"
          formControlName="email"
          [ngClass]="{ 'is-invalid': submited && f.email.errors }"
          type="email"
          placeholder="Escribe aquí tu email"
        />
        <div *ngIf="submited && f.email.errors" class="invalid-feedback">
          <div *ngIf="f.email.errors.required">Email requerido</div>
          <div *ngIf="f.email.errors.email">Formato invalido</div>
        </div>
      </div>
      <div class="col-12">
        <label class="control-label texto-label">Contraseña</label>
        <input
          [type]="tipoInput"
          placeholder="Escribe aquí tu contraseña"
          class="form-control  input-login"
          formControlName="password"
          [ngClass]="{ 'is-invalid': submited && f.password.errors }"
        />
        <i
          *ngIf="!mostrar"
          (click)="toogleIcono()"
          class="fa fa-eye icono-password"
        ></i>
        <i
          *ngIf="mostrar"
          (click)="toogleIcono()"
          class="fa fa-eye-slash icono-password"
        ></i>
        <div *ngIf="submited && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">Password requerido</div>
        </div>
      </div>
      <div class="col-12">
        <label class="texto-olvide" (click)="onOlvidar()">¿Olvidaste tu contraseña?</label>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-12 mb-12">
        <button type="button" class="btn-crear" (click)="onCrearCuenta()" >Crear una cuenta</button>
      </div>
      <div class="col-12 col-sm-6 mb-12">
        <button type="submit" class="btn-iniciar">Iniciar sesión</button>
      </div>
    </div>
    <ngx-spinner
      bdColor="rgba(0, 0, 0, 0.1)"
      size="medium"
      color="#fff"
      type="square-jelly-box"
      [fullScreen]="true"
      ><p class="texto-blanco">Verificando...</p></ngx-spinner
    >
  </form>
</div>

