<div class="row">
  <div class="col-12">
    <h3 class="titulo">
      ¡Premio del mes!
    </h3>
  </div>
</div>
<div class="row" *ngIf="cargado">

  <!-- <div class="col-12 col-sm-8">
    <div class="div-premio">
      <div class="row">
        <div class="col-12">
          <h3 class="titulo-banner">{{ premio.titulo }}</h3>
        </div>
        <div class="col-12 col-sm-8">
          <p class="texto-banner">{{ premio.texto }}</p>
        </div>
        <div class="col-12 col-sm-4">
          <img [src]="premio.logo.url" class="img-fluid" />
        </div>
      </div>
    </div>
  </div> -->
  <div class="col-md-12 img-premio" [ngStyle]="{ background: 'url(' + premio.imagen.url + ')' }">

  </div>
</div>
<div class="row marginT-20">
  <div class="col-sm-3 col-12">
    Seleccion mes y año
  </div>

  <div class="col-sm-3 col-12">
    <select class="form-control" [(ngModel)]="mes">
      <option value="1">Enero</option>
      <option value="2">Febrero</option>
      <option value="3">Marzo</option>
      <option value="4">Abril</option>
      <option value="5">Mayo</option>
      <option value="6">Junio</option>
      <option value="7">Julio</option>
      <option value="8">Agosto</option>
      <option value="9">Septiembre</option>
      <option value="10">Octubre</option>
      <option value="11">Noviembre</option>
      <option value="12">Diciembre</option>
    </select>
  </div>
  <div class="col-sm-3 col-12">
    <select class="form-control" [(ngModel)]="anio">
      <option value="2020">2020</option>
      <option value="2020">2021</option>
      <option value="2020">2022</option>
      <option value="2020">2023</option>
    </select>
  </div>
  <div class="col-sm-3 col-12">
    <button type="button" class="boton-guardar" (click)="getRankingMes()">
      Consultar
    </button>
  </div>
</div>
<div class="row marginT-20" *ngIf="cargado">
  <div class="table-responsive">
    <table class="table table-bordered table-striped">
      <thead>
        <tr>
          <th>Posición</th>
          <th>Nombre</th>
          <th>Ferreteria</th>
          <th>Puntos</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of ranking; let i = index">
          <td>
            {{ i + 1 }}
            <img
              src="/assets/img/medalla-oro.png"
              class="icono-medalla"
              *ngIf="i === 0"
            />
            <img
              src="/assets/img/medalla-plata.png"
              class="icono-medalla"
              *ngIf="i === 1"
            />
            <img
              src="/assets/img/medalla-bronce.png"
              class="icono-medalla"
              *ngIf="i === 2"
            />
          </td>
          <td>{{ item.usuarioR[0].nombre }}</td>
          <td>{{ item.usuarioR[0].ferreteria }}</td>
          <td>{{ item.totalPoints }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
