<div class="fondo-juego" *ngIf="AbrirJuego === false">
  <div class="start" *ngIf="AbrirJuego === false">
    <div class="div-logo-principal mx-auto">
      <img src="/assets/img/juego/logo-principal.png" class="imagen-juego" />
    </div>
    <div class="div-boton">
      <div class="row no-margin-right">
        <div class="col-sm-2 col-md-3"></div>
        <div class="col-sm-8 col-md-6 plr-0">
          <button class="boton-jugar2" type="button" (click)="onJugar()">
            Jugar
          </button>
        </div>
      </div>
      <div class="row  no-margin-right">
        <div class="col-sm-3 col-md-4"></div>
        <div class="col-sm-6 col-md-4 plr-0">
          <div class="div-consejo">
            <p class="texto-consejo">
              <b>Consejo:</b> Recuerda usar todas las ayudas para obtener un
              mejor resultado.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="fondo-juego2" *ngIf="AbrirJuego !== false">
  <div *ngIf="cargaCompleta">
    <div *ngIf="AbrirJuego === true" [hidden]="
        juegoEnPausa === false ||
        escalonSeguro === true ||
        finalizandoJuego === true
      ">
      <div class="preguntas  ">
        <img class="imagenFlotante1" src="/assets/img/juego/logo-principal.png" />
        <img class="imagenFlotante2" src="/assets/img/juego/logo-principal.png" />
        <img class="imagenFlotante3" src="/assets/img/juego/fondo-preguntas.png" />
        <img class="imagenFlotante4" src="/assets/img/juego/fondo-preguntas.png" />
        <img class="imagenFlotante5" src="/assets/img/juego/icono-50-puntos.png" />
        <img class="imagenFlotante6" src="/assets/img/juego/icono-100-puntos.png" />
        <div class="row encima">
          <div class=" col-0 col-sm-4 col-md-4"></div>
          <div class="col-sm-4 col-md-4">
            <div class="row" *ngFor="let item of preguntas; let i = index">
              <div class="col-12 encima text-center">
                <button class="boton-jugar " [ngClass]="{
                    respondida: respuestaCorrecta && 14 - progreso <= i
                  }" type="button" *ngIf="i != 0 && i != 5 && i != 10">
                  {{ item }}
                </button>
                <button class="boton-jugar-100 mx-auto" type="button" *ngIf="i === 5" [ngClass]="{
                    respondida: respuestaCorrecta && 14 - progreso <= i
                  }">
                  {{ item }}
                </button>
                <button class="boton-jugar-50 mx-auto" type="button" *ngIf="i === 10" [ngClass]="{
                    respondida: respuestaCorrecta && 14 - progreso <= i
                  }">
                  {{ item }}
                </button>
                <button class="boton-jugar-200 mx-auto" type="button" *ngIf="i == 0" [ngClass]="{
                    respondida: respuestaCorrecta && 14 - progreso <= i
                  }">
                  <div class="principal">{{ item }}</div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3 col-sm-4 col-md-3 col-xl-3"></div>
          <div class="col-3 col-sm-2 col-md-3 col-xl-3">
            <button class="float-left btn btn-link button-footer" (click)="onVolver()">
              Salir
            </button>
          </div>
          <div class="col-3 col-sm-2 col-md-3 col-xl-3">
            <button class="float-right btn btn-link button-footer" *ngIf="!iniciarJuego" (click)="onIniciar()">
              Iniciar
            </button>
            <button class="float-right btn btn-link button-footer" *ngIf="iniciarJuego" (click)="onSiguientePregunta()">
              Continuar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Aca inicia seccion de las preguntas del juego -->
    <div *ngIf="AbrirJuego === true && juegoEnPausa === false">
      <div class="row">
        <div class="col-12 float-right marginR-15">
          <img *ngFor="let com of descripComodin; let i = index" [hidden]="com.usado" [src]="com.img"
            class="icono-ayuda " [ngClass]="{ 'comodin-activo': comodinSeleccionado === i }"
            (click)="seleccionarComodin({ comodin: i })" />
          <p class=" btn btn-link button-footer2 texto-ayuda float-right">
            Ayudas
          </p>
        </div>

        <div class="col-12">
          <div class="div-logo-principal2 mx-auto">
            <img src="/assets/img/juego/logo-conteo-tiempo.png" class="imagen-juego" />
            <div class="contador">{{ seconds }}</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-2 col-md-3 col-xl-4"></div>
        <div class="col-12 col-sm-8 col-md-6 col-xl-4 encima">
          <div class="div-pregunta">
            <p>{{ preguntaActiva.pregunta }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-2 col-md-3 col-xl-3"></div>
        <div class="col-12 col-sm-8 col-md-6 col-xl-6">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6 col-xl-6 encima"
              *ngFor="let item of preguntaActiva.opciones; let i = index">
              <div class="div-respuestas" [ngClass]="{
                  'fondoCorrecta':
                    opcionSeleccionada === i && item.correcta === true || opcionCorrecta === i,
                  'fondoIncorrecta':
                    opcionSeleccionada === i && item.correcta === false,
                  'recomendado':
                    opcionRecomendada == i && opcionSeleccionada === -1
                }" (click)="seleccionaRespuesta({ item: item, opcion: i })">
                <p [hidden]="opcionesOcultar.indexOf(i) > -1">
                  <span>{{ letras[i] }}</span>{{ item.respuesta }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p class="progreso">PREGUNTA {{ progreso + 1 }}/15</p>
      <div class="esfera-juego"></div>
    </div>
    <!-- Fin seccion muestra pregunta del juego -->

    <div class="contenedor-final" *ngIf="AbrirJuego === true" [hidden]="
        juegoEnPausa === false ||
        escalonSeguro === false ||
        finalizandoJuego === true
      ">
      <div class="div-logo-principal mx-auto">
        <img src="/assets/img/juego/icono-50-puntos.png" *ngIf="progreso === 4" />
        <img src="/assets/img/juego/icono-100-puntos.png" *ngIf="progreso === 9" />
        <img src="/assets/img/juego/icono-200-puntos.png" *ngIf="progreso === 14" />
      </div>
      <div class="div-posicion-consejo">
        <div class="row">
          <div class="col-12">
            <div class="div-consejo">
              <p class="texto-consejo" *ngIf="progreso === 4">
                Conseguiste un seguro por 50 puntos. Ánimo ferretero vas muy
                bien
              </p>
              <p class="texto-consejo" *ngIf="progreso === 9">
                Conseguiste un seguro por 100 puntos. Ánimo ferretero vas muy
                bien
              </p>
              <p class="texto-consejo" *ngIf="progreso === 14">
                ¡Felicitaciones!<br />
                Has ganado 200 puntos y eres un Ferretero Millonario
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- div de finalizar el juego -->
    <div class="contenedor-final" *ngIf="finalizandoJuego === true">

      <div class="fondo-modal"></div>
      <div class="div-modal">
        <div class="row">
          <div class="col-12">
            <label class="titulo-modal float-r cursor" (click)="reiniciarJuego()">X</label>
          </div>
          <div class="col-12">
            <p class="texto-final" *ngIf="!cuotaDiaria">
              Ganaste {{puntos}} puntos
            </p>
            <p class="texto-final" *ngIf="cuotaDiaria">
              Ya ganaste los 1,000 puntos del día ¡Continua mañana!
            </p>
          </div>
          <div class="col-12">
            <img id="imagenBanner" #imagenBanner class="img-fluid imagen" [src]="banner.imagen.url" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p class="texto-blanco">Espere por favor...</p>
</ngx-spinner>
<div class="fondo-modal" *ngIf="modalMensajes"></div>
<div class="modal-juego" *ngIf="modalMensajes">
  <!-- Div para modals de errores -->
  <div *ngIf="msgError || msgErrorAgotado">
    <div class="row">
      <div class="col-2 col-sm-2 col-md-3 col-xl-3"></div>
      <div class="col-8 col-sm-8 col-md-6 col-xl-6 encima ">
        <div class="modal-juego-imagen">
          <div class="row">
            <div class="col-3 col-sm-3 col-md-2 col-xl-2"></div>
            <div class="col-6 col-sm-6 col-md-8 col-xl-8">
              <div class=" titulo-modal-msg">¡Incorrecta!</div>
            </div>
            <div class="col-3 col-sm-3 col-md-2 col-xl-2">
              <button class="boton-modal float-right btn btn-link button-modal" (click)="cerrarError()">
                x
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-8 col-sm-8 col-md-8 col-xl-8">
              <p class="preguntaIncorrecta" *ngIf="msgError">
                Tu respuesta es incorrecta intentalo de nuevo!
              </p>
              <p class="preguntaIncorrecta" *ngIf="msgErrorAgotado">
                Tu tiempo para responder la pregunta se a agotado, intentalo de
                nuevo!
              </p>
            </div>
            <div class="col-4 col-sm-4 col-md-4 col-xl-4">
              <img class="logo-modal mx-auto " src="/assets/img/juego/ayuda-ferreteros.png" />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 col-md-3"></div>
            <div class="col-sm-4 col-md-6"></div>
            <div class="col-sm-4 col-md-3">
              <button class="boton-modal float-right btn btn-link button-modal" (click)="cerrarError()">
                Salir
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Div para modals de comodines -->
  <div *ngIf="comodinSeleccionado !== -1">
    <div class="row">
      <div class="col-12 col-sm-2 col-md-3 col-xl-4"></div>
      <div class="col-12 col-sm-8 col-md-6 col-xl-4 encima ">
        <div class="modal-juego-imagen">
          <div class="row">
            <div class="col-3 col-sm-3 col-md-2 col-xl-2"></div>
            <div class="col-6 col-sm-6 col-md-8 col-xl-8">
              <p class=" titulo-modal-msg">
                {{ descripComodin[comodinSeleccionado].titulo }}
              </p>
            </div>
            <div class="col-3 col-sm-3 col-md-2 col-xl-2">
              <button class="boton-modal float-right btn btn-link button-modal" (click)="cerrarError()">
                x
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-8 col-sm-8 col-md-8 col-xl-8">
              <p class="preguntaIncorrecta">
                {{ descripComodin[comodinSeleccionado].texto }}
              </p>
            </div>
            <div class="col-4 col-sm-4 col-md-4 col-xl-4">
              <img class="logo-modal mx-auto " [src]="descripComodin[comodinSeleccionado].img" />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 col-md-3"></div>
            <div class="col-sm-4 col-md-6"></div>
            <div class="col-sm-4 col-md-3">
              <button class="boton-modal float-right btn btn-link button-modal" (click)="ejecutarComodin()">
                Continuar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- div de escalon seguro -->
