import { UtilService } from './../../services/util.service';
import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Optional,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';
import { PuntosInterface } from 'src/app/models/puntos';

declare interface OfertasImagenes {
  imagen: string;
}

import { NoticiasInterface } from 'src/app/models/noticias';
import { UsuarioInterface } from '../../models/user';
import { BannerInterface } from 'src/app/models/banner';
import { DomSanitizer } from '@angular/platform-browser';
import { Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { LinkService } from 'src/app/services/link.service';
@Component({
  selector: 'noticia-detalle',
  templateUrl: './noticia-detalle.component.html',
  styleUrls: ['./noticia-detalle.component.scss'],
})
export class NoticiaDetalleComponent implements OnInit {
  /** Arreglo que almacena la lista de noticias Recientes */
  listaNoticias: NoticiasInterface[] = [];
  /** Objeto de noticia a mostrar */
  public noticia: NoticiasInterface;
  /** usuario activo */
  usuario: UsuarioInterface;
  /** Variable para verificar si el usuario esta logueado */
  usuarioLogueado = false;
  /** usuario activo */
  usuarioLocal: UsuarioInterface;
  /** Puntos cuando se guarda */
  puntosGuardar: number = 10;
  /** Puntos cuando se da like */
  puntosLike: number = 5;
  /** Lista de comentarios de la noticia a mostrar */
  public listaComentarios = [];
  /** Almacena el id de la noticia activa */
  public id: any;
  /** Bandera para determinar si se cargo la informacion de la noticia */
  public cargado = false;
  /** Bandera para verificar si la noticia fue guardada por el usuario */
  save: boolean = false;
  /** Bandera para verificar si la noticia tiene like por parte del usuario*/
  like: boolean = false;
  /** icono del reloj*/
  faClock = faClock;
  /** variable de control para mostrar y ocultar modal de compartir */
  modalCompartir: boolean = false;
  /** Banners de las noticas */
  bannerSuperior: BannerInterface;
  bannerInferior: BannerInterface;
  /** Verifica si el usuario ya comento en la noticia */
  puedeComentar = true;
  /** video */
  video: any;
  /** Objeto de noticias desde localStorage */
  storeNoticias: any;
  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private title: Title,
    private meta: Meta,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private mainService: MainService,
    public utilSevice: UtilService,
    public authService: AuthService,
    private linkService: LinkService,
    public utilService: UtilService,
    @Optional() @Inject('REQUEST') private request: Request
  ) {
    this.storeNoticias = this.utilService.dataNoticias;
    this.usuarioLocal = this.authService.getUser();
    this.authService.logueado.subscribe((logueado) => {
      this.usuarioLogueado = logueado;
      if (logueado) {
        this.usuarioLocal = this.authService.getUser();
      }
      this.route.params.subscribe((params) => {
        this.id = params.id;
        this.utilSevice.startLoading();
        this.getNoticia();
      });
    });
  }

  ngOnInit(): void {}
  setSeo(title, description, image) {
    this.title.setTitle(description);
    this.meta.addTag({ property: 'og:title', content: title }, true);
    this.meta.addTag({ name: 'twitter:title', content: title });
    this.meta.addTag({ name: 'twitter:image:alt', content: title });
    this.meta.addTag({ property: 'og:image:alt', content: title });
    this.meta.addTag({ property: 'og:title', content: title });
    this.meta.addTag({ property: 'og:site_name', content: title });
    this.meta.addTag({ name: 'title', content: title });
    this.meta.addTag({ itemprop: 'name', content: title });
    this.linkService.addTag({ rel: 'image_src', href: image });
    this.meta.addTag({ name: 'twitter:image', content: image });
    this.meta.addTag({ itemprop: 'image', content: image });
    this.meta.addTag({ property: 'og:image', content: image });

    this.meta.addTag({ name: 'twitter:description', content: description });
    this.meta.addTag({ property: 'og:description', content: description });
    this.meta.addTag({
      name: 'description',
      property: 'og:description',
      content: description,
    });
    this.meta.addTag({ name: 'description', content: description });
    this.meta.addTag({ itemprop: 'description', content: description });

    //this.meta.addTag({ property: 'og:image', itemprop:"image",content: image });
    this.meta.addTag({ property: 'og:type', content: 'article' });
    this.meta.addTag({
      property: 'twitter:card',
      content: 'summary_large_image',
    });
    this.meta.addTag({ property: 'og:image:width', content: '300' });
    this.meta.addTag({ property: 'og:image:height', content: '200' });
    this.meta.addTag({
      property: 'og:url',
      content: 'https://www.yoferretero.com/noticias/' + this.noticia.slug,
    });
    this.meta.addTag({ property: 'og:image:secure_url', content: image });
    this.meta.addTag({ name: 'msapplication-TileImage', content: image });
  }

  /**
   * Obtiene el usuario activo para verificar si la noticia fue guardada o le dieron like
   */
  getUsuario() {
    if (this.usuarioLogueado) {
      this.mainService
        .get('api/user/' + this.usuarioLocal._id)
        .subscribe((user: UsuarioInterface) => {
          this.usuario = user;
          if (
            this.usuario.noticiasGuardadas === undefined ||
            this.usuario.noticiasGuardadas === null
          ) {
            this.usuario.noticiasGuardadas = [];
          }
          if (
            this.usuario.ofertasGuardadas === undefined ||
            this.usuario.ofertasGuardadas === null
          ) {
            this.usuario.ofertasGuardadas = [];
          }
          if (
            this.usuario.noticiasLike === undefined ||
            this.usuario.noticiasLike === null
          ) {
            this.usuario.noticiasLike = [];
          }
          if (
            this.usuario.ofertasLike === undefined ||
            this.usuario.ofertasLike === null
          ) {
            this.usuario.ofertasLike = [];
          }
          let findSave = this.usuario.noticiasGuardadas.indexOf(
            this.noticia._id
          );
          if (findSave !== -1) {
            this.save = true;
          }
          let findLike = this.usuario.noticiasLike.indexOf(this.noticia._id);
          if (findLike !== -1) {
            this.like = true;
          }
          this.getNoticias();
        });
    } else {
      this.getNoticias();
    }
  }
  /**
   * Obtener banners de publicidad
   */
  getBanners() {
    if (isPlatformBrowser(this.platformId)) {
      this.mainService
        .get('api/banner')
        .subscribe((banners: BannerInterface[]) => {
          for (let item of banners) {
            if (item.nombre === 'Superior web') {
              this.bannerSuperior = item;
            }
            if (item.nombre === 'Inferior web') {
              this.bannerInferior = item;
            }
          }
          this.cargado = true;
          this.guardarVisita();
          this.cargado = true;
          this.utilSevice.stopLoading();
        });
    }
  }
  /**
   * Metodo para guardar la visita del usuario
   */
  guardarVisita() {
    if (isPlatformBrowser(this.platformId)) {
      let visita = {
        plataforma: 'web',
        usuario: this.usuarioLocal._id,
        modulo: 'noticia',
        noticia: this.noticia._id,
      };
      this.utilSevice.guardarVisita(visita);
    }
  }

  /**
   * Obtiene la informacion de la notica a mostrar
   */
  getNoticia() {
    this.mainService
      .get('api/noticiaSlug/' + this.id)
      .subscribe((noticia: NoticiasInterface) => {
        this.noticia = noticia;
        this.setSeo(
          'YoFerretero::Noticias',
          this.noticia.titulo,
          this.noticia.imagen
        );
        if (
          this.noticia.video !== undefined &&
          this.noticia.video !== null &&
          this.noticia.video !== ''
        ) {
          this.video = this.getVideoIframe(this.noticia.video);
        }
        this.getComentarios();
      });
  }

  /**
   * Obtiene lista de comentarios de la noticia a mostrar
   */
  getComentarios() {
    //this.setSeo(this.noticia.titulo, this.noticia.titulo, this.noticia.imagen);
    if (isPlatformBrowser(this.platformId)) {
      this.mainService
        .get('api/comentariosNoticia/' + this.noticia._id)
        .subscribe((comentarios: any) => {
          if (comentarios.success) {
            this.listaComentarios = comentarios.data;
            for (let item of this.listaComentarios) {
              if (item.usuario._id === this.usuarioLocal._id) {
                this.puedeComentar = false;
              }
            }
          }
          this.getUsuario();
        });
    }
  }
  /**
   * Obtiene lista de noticias publicadas
   */
  getNoticias() {
    if (isPlatformBrowser(this.platformId)) {
      let now = new Date();
      this.mainService
        .get('api/noticiaPublicadas/' + now)
        .subscribe((noticias: NoticiasInterface[]) => {
          if (this.storeNoticias === JSON.stringify(noticias)) {
          } else {
            //this.utilService.storeNoticias(noticias);
            this.listaNoticias = noticias;
          }
          this.getBanners();
        });
    }
  }
  /**
   * Funcion para recargar la noticia si se creo el comentario desde el componente
   * @param event emisor para saber si se creo el comentario desde el componente
   */
  onComentarioCreado(event) {
    if (event === true) {
      this.getNoticia();
    }
  }
  /**
   * Metodo para agregar y quitar noticia de los guardados del usuario
   */
  toogleSave() {
    if (!this.usuarioLogueado) {
      this.utilSevice.showWarning({
        time: 5000,
        title: 'Alerta',
        msj: 'Inicia sesión para guardar la noticia!',
      });
      return false;
    }
    if (this.save) {
      let findSave = this.usuario.noticiasGuardadas.indexOf(this.noticia._id);
      this.usuario.noticiasGuardadas.splice(findSave, 1);
      this.usuario.puntos = this.usuario.puntos - this.puntosGuardar;
      this.quitarFavorito();
    } else {
      this.usuario.noticiasGuardadas.push(this.noticia._id);
      this.usuario.puntos = this.usuario.puntos + this.puntosGuardar;
      this.guardarFavorito();
    }
    this.save = !this.save;
  }
  /**
   * Metodo para agregar al modelo de noticias guardadas por  el usuario
   */
  guardarFavorito() {
    let data = {
      tipo: 'noticia',
      usuario: this.usuario._id,
      noticia: this.noticia._id,
    };
    this.mainService.post('api/saved', data).subscribe((result) => {
      let puntuacion: PuntosInterface = {
        evento: 'Agregar noticia a favoritos',
        referencia: this.noticia._id,
        usuario: this.usuario._id,
        puntos: this.puntosGuardar,
      };
      this.utilSevice.guardarHistorialDePuntos(puntuacion);
      this.modificarUsuario('saved');
    });
  }
  /**
   * Metodo para quitar al modelo de noticias guardadas por  el usuario
   */
  quitarFavorito() {
    this.mainService
      .delete('api/savedNoticia/' + this.usuario._id + '/' + this.noticia._id)
      .subscribe((result) => {
        let puntuacion: PuntosInterface = {
          evento: 'Quitar noticia de favoritos',
          referencia: this.noticia._id,
          usuario: this.usuario._id,
          puntos: this.puntosGuardar * -1,
        };
        this.utilSevice.guardarHistorialDePuntos(puntuacion);
        this.modificarUsuario('saved');
      });
  }
  /**
   * Metodo para agregar y quitar like de la noticia por el usuario
   */
  toogleLike() {
    if (!this.usuarioLogueado) {
      this.utilSevice.showWarning({
        time: 5000,
        title: 'Alerta',
        msj: 'Inicia sesión para dar like en la noticia!',
      });
      return false;
    }
    if (this.like) {
      let findLike = this.usuario.noticiasLike.indexOf(this.noticia._id);
      this.usuario.noticiasLike.splice(findLike, 1);
      this.usuario.puntos = this.usuario.puntos - this.puntosLike;
      this.quitarLike();
    } else {
      this.usuario.noticiasLike.push(this.noticia._id);
      this.usuario.puntos = this.usuario.puntos + this.puntosLike;
      this.guardarLike();
    }
    this.like = !this.like;
  }
  /**
   * Metodo para quitar like de noticias y del usuario
   */
  quitarLike() {
    let likes = 0;
    if (this.noticia.likes !== undefined && this.noticia.likes !== null) {
      likes = this.noticia.likes;
    }
    likes--;
    let noticiaUpdate = {
      likes: likes,
    };
    this.mainService
      .put('api/noticia/' + this.noticia._id, noticiaUpdate)
      .subscribe((result) => {
        let puntuacion: PuntosInterface = {
          evento: 'Quitar Like en noticia',
          referencia: this.noticia._id,
          usuario: this.usuario._id,
          puntos: this.puntosLike * -1,
        };
        this.utilSevice.guardarHistorialDePuntos(puntuacion);
        this.modificarUsuario('like');
        this.noticia.likes--;
      });
  }
  /**
   * Metodo para agregar al modelo de noticias guardadas por  el usuario
   */
  guardarLike() {
    let likes = 0;
    if (this.noticia.likes !== undefined && this.noticia.likes !== null) {
      likes = this.noticia.likes;
    }
    likes++;
    let noticiaUpdate = {
      likes: likes,
    };
    this.mainService
      .put('api/noticia/' + this.noticia._id, noticiaUpdate)
      .subscribe((result) => {
        let puntuacion: PuntosInterface = {
          evento: 'Like en noticia',
          referencia: this.noticia._id,
          usuario: this.usuario._id,
          puntos: this.puntosLike,
        };
        this.utilSevice.guardarHistorialDePuntos(puntuacion);
        this.modificarUsuario('like');
        this.noticia.likes++;
      });
  }
  /**
   * Actualizar datos del local storage de usuario
   */
  updateStorage() {
    let token = this.authService.getToken();
    this.authService.storeUserData(token, this.usuario);
  }
  /**
   * Metodo para actualizar datos del usuario al momento de dar like y guardar noticia
   * @param tipo identifica si se modifico like o guardar noticia
   */
  modificarUsuario(tipo) {
    this.mainService
      .put('api/user/' + this.usuario._id, this.usuario)
      .subscribe((result: any) => {
        if (result.success) {
          this.updateStorage();
        }
      });
  }
  /**
   * Genera enlace para compartir en redes sociales
   */
  enlaceShared() {
    return this.mainService.baseURLssr + 'noticias/' + this.noticia.slug;
  }
  /**
   * Funcion para carga de video de youtube
   */
  getVideoIframe(url) {
    let video, results;
    if (url === null) {
      return '';
    }
    results = url.match('[\\?&]v=([^&#]*)');
    video = results === null ? url : results[1];

    return this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://www.youtube.com/embed/' + video
    );
  }
  /**
   * Evento al compartir
   */
  compartiendo(){
    let puntuacion: PuntosInterface = {
      evento: "Compartir noticia",
      referencia: this.noticia._id,
      usuario: this.usuario._id,
      puntos: 5
    };
    this.utilService.guardarHistorialDePuntos(puntuacion);
  }
}
