<div class="fondo-modal"></div>
<div class="div-modal">
  <div class="row">
    <div class="col-12">
      <label class="titulo-modal float-r cursor" (click)="salir()">X</label>
    </div>
  </div>
  <div [hidden]="modalTerminos">
    <form [formGroup]="frmCrear" (ngSubmit)="onCrearCuenta()" novalidate>
      <div [hidden]="step !== 1">
        <div class="row">
          <div class="col-sm-4 col-3"></div>
          <div class="col-sm-4 col-6">
            <img class="img-fluid" src="/assets/img/logo-login.png" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label class="texto-iniciar">Crear cuenta</label>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div
              class="tarjeta-tipo"
              (click)="seleccionaTipo({ tipo: 'Ferretero' })"
            >
              <img class="img-tipo" src="/assets/img/ferretero.png" />
              <p class="texto-tipo">Ferretero</p>
            </div>
          </div>
          <div class="col-6">
            <div
              class="tarjeta-tipo"
              (click)="seleccionaTipo({ tipo: 'Proveedor' })"
            >
              <img class="img-tipo" src="/assets/img/proveedor.png" />
              <p class="texto-tipo">Proveedor</p>
            </div>
          </div>
        </div>
      </div>
      <div [hidden]="step !== 2">
        <div class="row">
          <div class="col-12">
            <i
              class="fa fa-arrow-left volver"
              (click)="setStep({ step: 1 })"
            ></i>
            <label class="texto-iniciar">Crear cuenta de {{ tipo }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-12" [formGroup]="frmCrear">
            <label class="control-label texto-label">Nombre completo</label>
            <input
              class="form-control input-login"
              formControlName="nombre"
              [ngClass]="{ 'is-invalid': submited && f.nombre.errors }"
              type="text"
              placeholder="Escribe aquí tu nombre"
            />
            <div *ngIf="submited && f.nombre.errors" class="invalid-feedback">
              <div *ngIf="f.nombre.errors.required">Nombre requerido</div>
            </div>
          </div>
          <div class="col-12" [formGroup]="frmCrear">
            <label class="control-label texto-label">Email</label>
            <input
              class="form-control input-login"
              formControlName="email"
              [ngClass]="{ 'is-invalid': submited && f.email.errors }"
              type="email"
              placeholder="Escribe aquí tu email"
            />
            <div *ngIf="submited && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email requerido</div>
              <div *ngIf="f.email.errors.email">Formato invalido</div>
            </div>
          </div>
          <div class="col-12" [formGroup]="frmCrear">
            <label class="control-label texto-label">Celular</label>
            <input
              type="tel"
              placeholder="Escribe aquí tu celular"
              class="form-control input-login"
              formControlName="celular"
              [ngClass]="{ 'is-invalid': submited && f.celular.errors }"
            />
            <div *ngIf="submited && f.celular.errors" class="invalid-feedback">
              <div *ngIf="f.celular.errors.required">Celular requerido</div>
            </div>
          </div>

          <div class="col-12" [formGroup]="frmCrear">
            <label
              class="control-label texto-label"
              *ngIf="tipo === 'Ferretero'"
              >Ferreteria</label
            >
            <label
              class="control-label texto-label"
              *ngIf="tipo === 'Proveedor'"
              >Marca</label
            >
            <input
              class="form-control input-login"
              formControlName="ferreteria"
              [ngClass]="{ 'is-invalid': submited && f.ferreteria.errors }"
              type="text"
              placeholder="Escribe aquí"
            />
            <div
              *ngIf="submited && f.ferreteria.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.ferreteria.errors.required && tipo === 'Ferretero'">
                Ferreteria requerido
              </div>
              <div *ngIf="f.ferreteria.errors.required && tipo === 'Proveedor'">
                Marca requerida
              </div>
            </div>
          </div>
          <div class="col-12" [formGroup]="frmCrear">
            <label class="control-label texto-label">Cargo</label>
            <select
              formControlName="cargo"
              class="form-control input-login"
              [ngClass]="{ 'is-invalid': submited && f.cargo.errors }"
              *ngIf="tipo === 'Ferretero'"
            >
              <option value="">Elige una opción</option>
              <option [value]="item" *ngFor="let item of optFerretero">
                {{ item }}
              </option>
            </select>
            <select
              formControlName="cargo"
              class="form-control input-login"
              [ngClass]="{ 'is-invalid': submited && f.cargo.errors }"
              *ngIf="tipo === 'Proveedor'"
            >
              <option value="">Elige una opción</option>
              <option [value]="item" *ngFor="let item of optProveedor">
                {{ item }}
              </option>
            </select>
            <div *ngIf="submited && f.cargo.errors" class="invalid-feedback">
              <div *ngIf="f.cargo.errors.required">Cargo requerido</div>
            </div>
          </div>

          <div class="col-12" [formGroup]="frmCrear">
            <label class="control-label texto-label">Ciudad</label>
            <ng-autocomplete
                [data]="ciudades"
                [ngClass]="{ 'is-invalid': submited && f.ubicacion.errors }"
                [searchKeyword]="keyword"
                (selected)="selectEventC($event)"
                [itemTemplate]="itemTemplateC"
                formControlName="ubicacion"
              >
              </ng-autocomplete>

              <ng-template #itemTemplateC let-item>
                <a [innerHTML]="item.ciudad"></a>
              </ng-template>
            <div *ngIf="submited && f.ubicacion.errors" class="invalid-feedback">
              <div *ngIf="f.ubicacion.errors.required">Ciudad requerida</div>
            </div>
          </div>

          <div class="col-12" [formGroup]="frmCrear">
            <label class="control-label texto-label">Contraseña</label>
            <input
              [type]="tipoInput"
              placeholder="Escribe aquí tu contraseña"
              class="form-control input-login"
              formControlName="password"
              [ngClass]="{ 'is-invalid': submited && f.password.errors }"
            />
            <i
              *ngIf="!mostrar"
              (click)="toogleIcono()"
              class="fa fa-eye icono-password"
            ></i>
            <i
              *ngIf="mostrar"
              (click)="toogleIcono()"
              class="fa fa-eye-slash icono-password"
            ></i>
            <div *ngIf="submited && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Password requerido</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <i
              class="fa fa-circle-o icono-terminos"
              *ngIf="!terminos"
              (click)="aceptarTerminos()"
            ></i>
            <i
              class="fa fa-check-circle-o icono-terminos"
              *ngIf="terminos"
              (click)="aceptarTerminos()"
            ></i>
            <label class="texto-olvide" (click)="goTerminos()">
              Acepto términos y condiciones
            </label>
          </div>
          <div class="col-12">
            <button type="submit" class="btn-iniciar">Registrarme</button>
          </div>
        </div>
      </div>

      <ngx-spinner
        bdColor="rgba(0, 0, 0, 0.1)"
        size="medium"
        color="#fff"
        type="square-jelly-box"
        [fullScreen]="true"
        ><p class="texto-blanco">Guardando...</p></ngx-spinner
      >
    </form>
  </div>
  <div [hidden]="!modalTerminos">
    <div class="row">
      <div class="col-2">
        <i class="fa fa-arrow-left volver" (click)="modalTerminos = false"></i>
      </div>
      <div class="col-12">
        <label class="texto-iniciar">Términos y condiciones de uso</label>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p class="texto-modal">
          Al momento de ingresar al presente sitio web www.yoferretero.com o
          utilizar cualquiera de nuestras plataformas tecnológicas incluida la
          app móvil YO FERRETERO, disponible para dispositivos Apple o Android,
          y/o al adquirir los productos o servicios ofrecidos en cualquiera de
          estos servicios, usted, en calidad de usuario, se obliga a leer y
          declara haber leído y aceptado en su integridad los presentes términos
          y condiciones de uso incluyendo nuestra política de privacidad.
        </p>
        <p class="texto-modal">
          Sin perjuicio de lo anterior, Medios Concept2bS.A.S. podrá hacer
          cambios totales o parciales en los presente términos y condiciones de
          uso o en su política de privacidad para el sitio www.yoferretero.com o
          para cualquiera de sus plataformas, colectiva o individualmente, sin
          previo aviso conforme a la legislación colombiana aplicable.
        </p>
        <p class="texto-modal">
          <b>1. Derechos de Propiedad intelectual e industrial</b>
        </p>
        <p class="texto-modal">
          Toda clase de información (imágenes, vídeos, herramientas, gráficos y
          demás), marcas, nombres, logos, lemas, enseñas comerciales y demás
          material que se encuentra en esta página web o en cualquiera de
          nuestras aplicaciones móviles están protegidos conforme a lo
          establecido en la legislación colombiana e internacional sobre
          derechos de autor, propiedad intelectual e industrial. En
          consecuencia, usted como usuario reconoce y acepta que únicamente
          podrá utilizar la propiedad intelectual que se encuentra en el sitio
          web para fines personales de revisión, compra y consulta.
        </p>
        <p class="texto-modal">
          En este orden de ideas, la publicación de los contenidos que se
          encuentran en www.yoferretero.como en nuestras aplicaciones móviles,
          dirigidos al público en general, de ninguna manera podrá interpretarse
          como como forma de concesión de ningún tipo de licencia de uso de
          marca, derechos de autor o propiedad intelectual a favor del usuario
          ni de terceros.
        </p>
        <p class="texto-modal">
          Cualquier uso no autorizado que infrinja las leyes colombianas o
          comunitarias en materia de propiedad intelectual, bien sea su venta,
          distribución, copia, modificación o adaptación, o cualquier otra
          conducta prohibida, será denunciado y sancionado de acuerdo a lo
          establecido por las normas aplicables.
        </p>
        <p class="texto-modal">
          <b>2. Uso Autorizado</b>
        </p>
        <p class="texto-modal">
          Se autoriza el uso del sitio www.yoferretero.com y de nuestras
          aplicaciones móviles o cualquier otra plataforma tecnológica para
          revisión, compra y consulta de productos nacionales y/o importados con
          fines comerciales o empresariales por parte del usuario.
        </p>
        <p class="texto-modal">
          <b>3. Usos prohibidos</b>
        </p>
        <p class="texto-modal">
          La información publicada en el sitio www.yoferretero.comno podrá ser
          objeto de comercialización, distribución, copia o modificación de
          ninguna clase sin la autorización previa, expresa y por escrito de
          Medios Concept2bS.A.S. o de su propietario.
        </p>
        <p class="texto-modal">
          No está autorizado el acceso a cuentas o información confidencial de
          los usuarios, así como la violación de la red y/o servidores o
          cualquier otro acto que atente contra la seguridad de las
          transacciones y de la página web o nuestras aplicaciones móviles.
        </p>
        <p class="texto-modal">
          La información que el usuario registre al inscribirse al servicio o al
          crear una cuenta debe ser veraz y completa; se prohíbe todo acto de
          suplantación de identidad y/o información incorrecta que no
          corresponda a la persona natural o jurídica que hace uso de la página
          web.
        </p>
        <p class="texto-modal">
          En todo caso, únicamente se permitirá a personas jurídicas o personas
          naturales mayores de edad, debidamente identificadas con cédula de
          ciudadanía o cédula de extranjería registrarse y efectuar operaciones
          de solicitud de ofertas a través de esta página.
        </p>
        <p class="texto-modal">
          <b>4. Información de los usuarios</b>
        </p>
        <p class="texto-modal">
          Para ser parte de la aplicación es necesario el registro por parte de
          los usuarios en la página web o la aplicación. El usuario deberá
          suministrar información personal y confidencial. Medios Concept2b
          S.A.S. será el responsable de la información suministrada por los
          usuarios y tratará la misma de conformidad con su política de
          tratamiento de datos, que el usuario declara conocer y aceptar al
          utilizar la página web y la aplicación.
        </p>
        <p class="texto-modal">
          Con el fin de garantizar la seguridad de los usuarios y el uso
          adecuado de la página web, queda expresamente prohibido a los usuarios
          compartir con terceros sus datos de acceso tales como usuario,
          contraseña, ciudad preguntas de seguridad y demás. La información
          mencionada es de uso exclusivo de cada usuario y no se podrá utilizar
          para fines no autorizados
        </p>
        <p class="texto-modal">
          YO FERRETERO no se hace responsable por la precisión, veracidad,
          contenido o punto de vista de las expresiones usadas por los usuarios
          en los comentarios, sin perjuicio que puedan ser retirados de la
          página sí resultan ofensivas, denigrantes o discriminatorias.
        </p>
        <p class="texto-modal">
          <b>5. Registro y Contraseña</b>
        </p>
        <p class="texto-modal">
          Para la adquisición de los productos y/o servicios ofrecidos en el
          sitio web www.yoferretero.como en laaplicación móvil será
          prerrequisito el registro del usuario y la definición de una clave de
          acceso, todo lo cual implica la aceptación de los presente términos y
          condiciones. Para tal efecto, en el proceso de creación de usuario y
          definición de clave de acceso el usuario deberá manifestar
          expresamente haber leído y aceptado los presentes Términos y
          Condiciones mediante de un "click" en el recuadro respectivo. El
          usuario deberá haber leído detenidamente los presente términos antes
          de aceptar los mismos y en caso de tener alguna duda al respecto
          deberá contactar a YO FERRETERO. antes de aceptar los términos de uso
          y registrarse como usuario.
        </p>
        <p class="texto-modal">
          El registro de cada usuario se verificará completando y suscribiendo
          el formulario que se encuentra en el sitio y su posterior envío, el
          cual se realiza automáticamente pulsando "click" en el elemento
          respectivo.
        </p>
        <p class="texto-modal">
          Una vez registrado, el usuario dispondrá de un nombre y contraseña o
          clave definitiva que le permitirá el acceso personalizado,
          confidencial y seguro a www.yoferretero.com o a la aplicación móvil.
        </p>
        <p class="texto-modal">
          El usuario es el único responsable por el mantenimiento de la
          confidencialidad de su clave secreta registrada en este sitio web, por
          medio de la cual podrá efectuar cotizaciones, solicitar servicios y
          obtener información.
        </p>
        <p class="texto-modal">
          Dicha clave es de uso personal y su entrega a terceros no involucra
          responsabilidad de YO FERRETERO, ni de las empresas relacionadas en
          caso de indebida utilización. Será responsabilidad exclusiva del
          usuario cambiar periódicamente su clave de acceso y tomar todas las
          demás medidas para evitar que terceros utilicen su nombre de usuario
          y/o clave para acceder fraudulentamente al sitio web, a la aplicación
          cualquiera de las plataformas
        </p>
        <p class="texto-modal">
          <b>6. Derechos del Usuario</b>
        </p>
        <p class="texto-modal">
          El usuario gozará de todos los derechos que le reconoce la legislación
          colombiana en materia comercial y de protección de datos. Además, el
          usuario podrá radicar sus peticiones, quejas, reclamos y sugerencias a
          través de las diferentes herramientas y mecanismos de comunicación
          establecidos en el sitio web www.yoferretero.com
        </p>
        <p class="texto-modal">
          <b>7. Disposiciones Generales y Contractuales</b>
        </p>
        <p class="texto-modal">
          Medios Concept2bS.A.S. actuará de forma diligente, procurará que el
          ingreso al sitio web www.yoferretero.como a nuestras aplicación móvilo
          plataformas tecnológicas sea seguro, y que tomará medidas prudentes
          para garantizar que la información personal de los usuarios se
          encuentra segura y resguardada. Sin perjuicio de lo anterior, YO
          FERRETERO no será responsable por cualquier clase de virus, demoras en
          la operación o transmisión, errores tecnológicos, manipulación por
          terceros no autorizados, cualquier evento de invasión o manipulación
          tecnológica o algún otro error en el funcionamiento de la página web.
        </p>
        <p class="texto-modal">
          Recuerde que únicamente podrá contratar a través de esta página web,
          aquellas personas que cuenten con plena capacidad legal para hacerlo y
          que actúes con una finalidad comercial intrínsecamente ligada a su
          actividad empresarial. La página web www.yoferretero.com no está
          diseñada para atender las necesidades del consumidor final cuando este
          consumidor no esté adquiriendo productos para una finalidad
          intrínsecamente ligada a su actividad profesional o comercial.
        </p>
        <p class="texto-modal">
          Las ofertas que en el sitio web www.yoferretero.com. O en cualquiera
          de nuestra aplicación móvil o plataformas tecnológicas permita la
          compra de productos o servicios a maestros de obra, constructores,
          obreros, arquitectos, o cualquier persona que utilice o pretenda
          utilizar los bienes adquiridos a para una finalidad distinta a su
          reventa, el usuario entiende y acepta que la página web
          www.yoferretero.com.o nuestras aplicación móvil o plataformas
          tecnológicas funcionarán como portales de intermediación de compra,
          cuya única función será poner en contacto a la marca dueña de los
          productos con una ferretería o comercio aliado, que a su vez es un
          usuario de YO FERRETERO. En este evento, el usuario entiende que los
          productos adquiridos por intermedio de YO FERRETERO serán vendidos
          directamente por la marca aliada por cuenta y riesgo de esta última,
          sin que exista ninguna clase de solidaridad ni responsabilidad por
          parte de YO FERRETERO en esta transacción.
        </p>
        <p class="texto-modal">
          Cuando por errores de carácter tecnológico o humano se exhiban
          descuentos erróneos de los productos en la página web o en la
          aplicación móvil o plataformas de YO FERRETERO, que evidentemente
          resulten desproporcionados, comparativamente con el precio del
          producto en el mercado, el YO FERRETERO o el aliado o comercio
          correspondiente podrán cancelar la(s) ordenes realizadas de productos
          con dichos precios, a su libre discreción y con plena autonomía.
        </p>
        <p class="texto-modal">
          YO FERRETERO y/o el comercio aliado se reservan el derecho de
          actualizar, modificar y/o descontinuar los productos exhibidos en su
          página web, aplicación móvil o plataformas tecnológicas sin previo
          aviso.
        </p>
        <p class="texto-modal">
          Eventualmente www.yoferretero.com podría presentar errores
          involuntarios de funcionamiento u operación. En caso de que alguno de
          estos errores sea detectado por el usuario, éste deberá informarlo de
          forma inmediata por medio de la sección “contacto” o similares
          ubicadas en la página web.
        </p>
        <p class="texto-modal">
          YO FERRETERO. actúa como intermediario en la venta de productos o
          servicios por parte de la marca a ferreterías o comercios aliados, YO
          FERETERO no expedirá ninguna clase de factura al comprador pues se
          entiende que la venta la realiza directamente el comercio o aliado
          correspondiente, quien deberá cumplir con sus propias obligaciones
          fiscales y comerciales de conformidad con la ley.
        </p>
        <p class="texto-modal">
          <b
            >8. Prevención de Lavado de Activos y Financiación del Terrorismo</b
          >
        </p>
        <p class="texto-modal">
          El usuario se obliga con YO FERRETERO a implementar las medidas
          tendientes a evitar que sus operaciones puedan ser utilizadas sin su
          conocimiento y consentimiento como instrumentos para el ocultamiento,
          manejo, inversión o aprovechamiento en cualquier forma de dinero u
          otros bienes provenientes de actividades delictivas o para dar
          apariencia de legalidad a estas actividades. En tal sentido acepta que
          YO FERRETERO podrá terminar de manera unilateral e inmediata la
          relación de negocio que se rija por los presente términos y
          condiciones cuando el usuario sea: (i) condenado por parte de las
          autoridades competentes a cualquier tipo de investigación por delitos
          de narcotráfico, terrorismo, secuestro, lavado de activos,
          financiación del terrorismo y administración de recursos relacionados
          con dichas actividades (ii) incluido en listas para el control de
          lavado de activos y financiación del terrorismo administradas por
          cualquier autoridad nacional o extranjera, tales como la lista de la
          Oficina de Control de Activos en el Exterior – OFAC emitida por la
          Oficina del Tesoro de los Estados Unidos de Norte América, la lista de
          la Organización de las Naciones Unidas y otras listas públicas
          relacionadas con el tema del lavado de activos y financiación del
          terrorismo, o (iii) condenado por parte de las autoridades competentes
          en cualquier tipo de proceso judicial relacionado con la comisión de
          los anteriores delitos.
        </p>
        <p class="texto-modal">
          <b>PARAGRAFO:</b>El usuario a) Autoriza a ser consultado en bases de
          datos, b) Acepta la terminación automática de la relación contractual
          en caso de encontrarse relacionado negativamente en listas o noticias
          por temas asociados al lavado de activos o financiación del
          terrorismo. c) Autoriza a revelar su información personal y de los
          negocios, en caso de ser requerida por una autoridad competente en
          Colombia como la UIAF o la Fiscalía General de la Nación. d) Se
          compromete a actualizar anualmente la información o en un tiempo menor
          en caso de que ocurran cambios en la información suministrada.
        </p>
        <p class="texto-modal">
          El usuario declara que no se encuentra en ninguna lista de las
          establecidas a nivel nacional o internacional para el control de
          lavado de activos y financiamiento del terrorismo; así mismo se
          responsabiliza ante YO FERRETERO porque sus empleados, accionistas,
          miembros de la junta directiva o junta de socios, sus representantes
          legales y su revisor fiscal, tampoco se encuentren en dichas listas y
          se compromete a actualizar anualmente la información o en un tiempo
          menor en caso de que ocurran cambios en la información suministrada a
          YO FERRETERO. Así mismo, el usuario autoriza a: (i) revelar su
          información personal y de los negocios, en caso de ser requerida por
          una autoridad competente en Colombia como la UIAF o la Fiscalía
          General de la Nación y; (ii) a ser consultado en bases de datos.
        </p>
        <p class="texto-modal">
          YO FERRETERO podrá terminar de manera unilateral e inmediata el
          presente contrato en caso que el usuario, sus socios o accionistas y/o
          sus administradores llegaren a ser: (i) vinculados por parte de las
          autoridades competentes a cualquier tipo de investigación por delitos
          de narcotráfico, terrorismo, lavado de activos, financiación del
          terrorismo, testaferrato, tráfico de estupefacientes o cualquier
          delito contra el orden constitucional (ii) ser incluido en listas para
          el control de lavado de activos y financiación del terrorismo
          administradas por cualquier autoridad nacional o extranjera, tales
          como la lista de la Oficina de Control de Activos en el Exterior OFAC
          emitida por la Oficina del Tesoro de los Estados Unidos de Norte
          América, la lista de la Organización de las Naciones Unidas y otras
          listas públicas relacionadas con el tema del lavado de activos y
          financiación del terrorismo, o (iii) condenado por parte de las
          autoridades competentes en cualquier tipo de proceso judicial
          relacionado con la comisión de delitos de igual o similar naturaleza a
          los indicados en esta cláusula.
        </p>
        <p class="texto-modal">
          El usuario, indemnizará y mantendrá libre de cualquier daño a YO
          FERRETERO por cualquier multa o perjuicio que fueran probados y que
          sufra YO FERRETERO por o con ocasión del incumplimiento por parte del
          usuario de las obligaciones que le apliquen en materia de prevención
          del riesgo de lavado de activos y financiación del terrorismo, así
          como cualquier reclamo judicial, extrajudicial y/o administrativo que
          autoridades competentes inicien en contra de YO FERRETERO por o con
          ocasión de dicho incumplimiento. De igual manera, el usuario se
          compromete a dar las explicaciones a que hubiere lugar originadas por
          el mencionado incumplimiento a YO FERRETERO y a los terceros que este
          le indique.
        </p>
        <p class="texto-modal">
          <b>9. Módulos de la aplicación </b>
        </p>
        <p class="texto-modal">
          Para cada uno de los módulos aplica las siguientes términos y
          condiciones:
        </p>
        <p class="texto-modal">
          <b>1. MODULO NOTICIAS:</b>YO FERRETERO no se hace responsable la
          información colocada por las marcas en la sección de noticias,
          Ferreofertas y preguntas del juego FERRETERO MILLONARIO.
        </p>
        <p class="texto-modal">
          <b>2. MODULO FERREOFERTAS:</b>YO FERRETERO actúa como intermediario
          sin que por este hecho se entienda que YO FERRETERO adquiere ninguna
          clase de responsabilidad en el contrato que se celebre entre comprador
          y vendedor.
        </p>
        <p class="texto-modal">
          <b>Cargos por envío e impuestos: </b> Los cargos de envíos e
          impuestos, según la oferta estarán a cargo del usuario y /o vendedor a
          que haya lugar en razón de su compra.
        </p>
        <p class="texto-modal">
          <b>Entrega:</b> La entrega de un producto o servicio adquirido será
          coordinada entre las partes. YO FERRETERO no es responsable por las
          demoras generadas por las mismas.
        </p>
        <p class="texto-modal">
          Las cotizaciones generadas en el módulo Ferreofertas estará sujeta a
          disponibilidad del inventario de la marca oferente y los tiempos de
          entrega serán estimados y estarán sujetos a disponibilidad y tiempos
          de entrega fijados por el aliado o comercio correspondiente.
        </p>
        <p class="texto-modal">
          <b>3. MODULO GUIA DE PROVEEDORES: </b> La información condensada aquí
          puede ser actualizada, modificada o eliminada, siempre y cuando
          demuestre ser la marca, y se realiza mediante correo a
          comercial2@agenciab2b.co.
        </p>
        <p class="texto-modal">
          <b>4. MODULO FERRETERO MILLONARIO: PREMIO DEL MES</b>
        </p>
        <p class="texto-modal">
          Cada mes solo podrá haber un ganador. El ganador queda inhabilitado
          para ganar un segundo premio, podrá seguir jugando, pero no acumulará
          puntos. El usuario puede generar estos puntos por interactuar en los
          diferentes módulos así:
        </p>
        <p class="texto-modal">
          <b>- Dar like a las noticias o Ferreofertas: </b>5 puntos
        </p>
        <p class="texto-modal">
          <b>- Dejar comentario en las noticias: </b> 20 puntos
        </p>
        <p class="texto-modal">
          <b>- Compartir noticias o Ferreofertas</b>(Nota: se entregarán los
          puntos una vez sea efectivamente compartidas a través de redes
          sociales o wapp, sin perjuicio de que la aplicación verifique esta
          acción y pueda eliminar los puntos en caso de que el usuario este
          generando los puntos sin compartir efectivamente la noticia): 5 puntos
        </p>
        <p class="texto-modal">
          <b>- Guardar noticias o Ferreofertas: </b>10 puntos
        </p>
        <p class="texto-modal">
          <b>- Solicitar cotización o contacto a proveedor:</b> 30 puntos
        </p>
        <p class="texto-modal">
          <b>- Jugar Ferretero Millonario: </b> hasta 200 puntos. Se permitirá
          al día una ganancia máxima de 1.000 puntos, a partir del momento en
          que la persona llegue a este puntaje, podrá seguir jugando, pero ya no
          acumula puntos por ese día.
        </p>
        <p class="texto-modal">
          El usuario que genere estos puntos de manera ilegal, fraudulenta o no
          cumpliendo los lineamientos y políticas diseñados por la aplicación y
          sitio web, podrá ser expulsado de la aplicación o seguir
          interactuando, pero ya no acumulara puntos, según se decida.
        </p>
        <p class="texto-modal">
          YO FERRETEROse reserva el derecho a modificar, cambiar o eliminar
          puntos ganados de manera incorrecta, según los términos y condiciones
          aquí acordados.
        </p>
        <p class="texto-modal">
          Para hacer la entrega del premio del mes, el ganador deberá acreditar
          que esta vinculado directamente al sector ferretero y será validado a
          través de alguna o varias de estas acciones: Rut, cámara de comercio,
          certificación del dueño de la ferretearía o atreves de otros medios
          que YO FERRETERO considere válidos (visita a la ferretería, solicitud
          de referencia para certificar su vinculación con el sector entre
          otros.).
        </p>
        <p class="texto-modal">
          El ganador autoriza a YO FERRETERO a tomar evidencia y publicar
          fotografía, video y datos de la entrega del premio.
        </p>
        <p class="titulo-modal">
          <b>POLÍTICA DE PRIVACIDAD</b>
        </p>
        <p class="texto-modal">
          Los datos personales de los usuarios se mantendrán bajo estricta
          confidencialidad y serán guardados y utilizados diligentemente por YO
          FERRETERO
        </p>
        <p class="texto-modal">
          Como parte del proceso de registro, lo usuarios deberán suministrar a
          YO FERRETERO. sus Datos Personales tales como: nombre, documento de
          identificación, Ciudad, dirección electrónica, número telefónico,
          entre otros.
        </p>
        <p class="texto-modal">
          Una vez se ha completado el formulario de registro la transferencia de
          información de otros sitios o redes sociales tales como Facebook,
          Twitter, Google, etc., el usuario autoriza expresa e inequívocamente,
          y así lo manifiesta al utilizar la plataforma y/o aceptar los presente
          términos y condiciones, el uso de sus datos personales para que YO
          FERRETERO lleve a cabo operaciones de tratamiento de Datos Personales
          conforme a las finalidades permitidas por la ley colombiana de
          Protección de Datos Personales (1581 de 2012) y su Decreto
          Reglamentario (1377 de 2013). Los Datos Personales serán sometidos a
          Tratamiento con las siguientes finalidades:
        </p>
        <p class="texto-modal">
          - Efectuar las gestiones pertinentes para la ejecución de actividades
          comerciales y transaccionales, así como el desarrollo de la etapa
          precontractual, contractual y pos contractual.
        </p>
        <p class="texto-modal">
          - Todos aquellos aspectos asociados a las actividades comerciales y
          promocionales de sus distintas líneas de negocios y servicios, y
          cualquier otro relacionado con el desarrollo del objeto social de YO
          FERRETERO y sus aliados comerciales y empresas vinculadas. Lo anterior
          implica el uso de los datos mencionados en actividades de mercadeo;
          envío de publicidad comercial; análisis estadístico con fines de
          prospección comercial; conocimiento del usuario; análisis de
          tendencias en compras; medición de la satisfacción del usuario,
          atención y respuesta de PQR´s; ofrecimiento de actividades
          promocionales; beneficios de alianzas comerciales; capacitación e
          información sobre productos y servicios, a través de diferentes
          canales de información, tales como envío de mensajes de texto,
          contacto en redes sociales, medios telefónicos, electrónicos y
          cualquier otro medio de contacto que la tecnología permita.
        </p>
        <p class="texto-modal">
          - Cuando se requiera, la transferencia y/o transmisión de los datos a
          un tercero, inclusive fuera del país (aún si el país receptor no
          ofrece un nivel adecuado de protección de datos), según la
          normatividad vigente de protección de datos personales.
        </p>
        <p class="texto-modal">
          - Con fines administrativos y/o comerciales como: análisis
          estadísticos, investigación de mercados, auditorías contables y
          financieras, facturación, ofrecimiento y/o reconocimiento de
          beneficios propios legales y/o contractuales, telemercadeo, cobranzas,
          cumplimiento de protocolos de seguridad y ofrecimiento de medios de
          financiación.
        </p>
        <p class="texto-modal">
          - Dar cumplimiento a la ley colombiana o extranjera y a las órdenes de
          autoridades judiciales o administrativas
        </p>
        <p class="texto-modal">
          Además, los datos se usarán para informar al usuario sobre cambios que
          puedan afectar sustancialmente nuestra política de protección de datos
          personales.
        </p>
        <p class="texto-modal">
          El usuario entiende y acepta que el sitio web www.yoferretero.com
          podrá capturar información referente a actividades específicas de un
          usuario particular, generándose reportes que le permiten a YO
          FERRETERO. ver la actividad consolidada por usuario. Así mismo, es
          entendido y aceptado que YO FERRETERO a través de su sitio web
          www.yoferretero.com usa "cookies" para enviarle información específica
          referida a los intereses particulares de cada usuario.
        </p>
        <p class="texto-modal">
          Este Sitio puede contener "links" a otros sitios. YO FERRETERO no es
          responsable de las prácticas de seguridad o privacidad, o el contenido
          de esos sitios. Asimismo, YO FERRETERO no avala ningún producto o
          servicio ofrecido en dichos sitios.
        </p>
        <p class="texto-modal">
          Cualquier consulta acerca de los presentes términos y condiciones,
          nuestra política de privacidad o cualquier otro aspecto del uso del
          sitio web www.yoferretero.com puede ser dirigida a YO FERRETERO. a
          través de los canales de servicio al cliente habilitados conforme al
          presente documento.
        </p>
        <p class="texto-modal">
          La presente Política y los términos y condiciones de
          www.yoferretero.com rigen a partir de su publicación y se entiende
          vigente durante todo el término de la relación existente entre usted y
          YO FERRETERO. La presente Política se rige por las leyes de la
          República de Colombia.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label class="texto-iniciar">Politica de tratamiento de datos personales</label>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p class="texto-modal">
          <b>OBJETIVO:</b> Establecer los criterios para la recolección,
          almacenamiento, uso, circulación, transferencia y supresión de los
          datos personales tratados por MEDIOS CONCEPT2B SAS
        </p>
        <p class="texto-modal">
          <b>ALCANCE:</b> Esta política aplica para toda la información
          personal registrada en las bases de datos de MEDIOS CONCEPT2B SAS,
          quien actúa en calidad de responsable del tratamiento de los datos
          personales.
        </p>
        <p class="texto-modal">
          <b>OBLIGACIONES:</b> Esta política es de obligatorio y estricto
          cumplimiento para MEDIOS CONCEPT2B SAS.
        </p>
        <p class="texto-modal">
          <b>RESPONSABLE DEL TRATAMIENTO:</b> MEDIOS CONCEPT2B SAS, sociedad
          comercial legalmente constituida, identificada con el NIT
          901.331.727 - 4, con domicilio principal en la Calle 106 No 57-23
          of 402 de la ciudad de Bogotá, República de Colombia. Página
          www.agenciab2b.co, www.gransalonferretero.com,
          www.yoferretero.com, Teléfono 7903005 en la ciudad de Bogotá
        </p>
        <p class="texto-modal">
          <b>TRATAMIENTO Y FINALIDAD:</b> El tratamiento que realizará
          MEDIOS CONCEPT2B SAS con la información personal será el
          siguiente: la recolección, almacenamiento, uso, circulación,
          transferencia y supresión para las siguientes finalidades:
        </p>
        <p class="texto-modal">
          a) Acceder en forma gratuita a los datos proporcionados que hayan
          sido objeto de tratamiento.
        </p>
        <p class="texto-modal">
          b) Solicitar la actualización y rectificación de su información
          frente a datos parciales, inexactos, incompletos, fraccionados,
          que induzcan a error, o a aquellos cuyo tratamiento esté prohibido
          o no haya sido autorizado.
        </p>
        <p class="texto-modal">
          c) Solicitar prueba de la autorización otorgada.
        </p>
        <p class="texto-modal">
          d) Presentar ante la Superintendencia de Industria y Comercio
          (SIC) quejas por infracciones a lo dispuesto en la normatividad
          vigente.
        </p>
        <p class="texto-modal">
          e) Revocar la autorización y/o solicitar la supresión del dato, a
          menos que exista un deber legal o contractual que haga imperativo
          conservar la información.
        </p>
        <p class="texto-modal">
          f) Abstenerse de responder las preguntas sobre datos sensibles o
          sobre datos de las niñas y niños y adolescentes.
        </p>
        <p class="texto-modal">
          <b>DERECHOS DE LOS TITULARES:</b> Como titular de sus datos
          personales Usted tiene derecho a:
        </p>
        <p class="texto-modal">
          (i) Acceder de forma gratuita a los datos proporcionados que hayan
          sido objeto de tratamiento.
        </p>
        <p class="texto-modal">
          (ii) Conocer, actualizar y rectificar su información frente a
          datos parciales, inexactos, incompletos, fraccionados, que
          induzcan a error, o aquellos cuyo tratamiento esté prohibido o no
          haya sido autorizado.
        </p>
        <p class="texto-modal">
          (iii) Solicitar prueba de la autorización otorgada.
        </p>
        <p class="texto-modal">
          (iv) Presentar ante la Superintendencia de Industria y Comercio
          (SIC) quejas por infracciones a lo dispuesto en la normatividad
          vigente.
        </p>
        <p class="texto-modal">
          (v) Revocar la autorización y/o solicitar la supresión del dato,
          siempre que no exista un deber legal o contractual que impida
          eliminarlos.
        </p>
        <p class="texto-modal">
          <b>ATENCIÓN DE PETICIONES, CONSULTAS Y RECLAMOS</b> El área de
          Gerencia de proyectos de MEDIOS CONCEPT2B SAS es la dependencia
          que tiene a cargo dar trámite a las solicitudes de los titulares
          para hacer efectivos sus derechos. Celular: 3508830531, email:
          comercial2@agenciab2b.co, pagina web: www.agenciab2b.co,
          www.gransalonferretero.com, www.yoferretero.com.
        </p>
        <p class="texto-modal">
          <b>PROCEDIMIENTO PARA EL EJERCICIO DEL DERECHO DE HABEAS DATA</b>
          En cumplimiento de las normas sobre protección de datos
          personales, MEDIOS CONCEPT2B SAS presenta el procedimiento y
          requisitos mínimos para el ejercicio de sus derechos: Para la
          radicación y atención de su solicitud le solicitamos suministrar
          la siguiente información: Nombre completo y apellidos Datos de
          contacto (Dirección física y/o electrónica y teléfonos de
          contacto), Medios para recibir respuesta a su solicitud,
          Motivo(s)/hecho(s) que dan lugar al reclamo con una breve
          descripción del derecho que desea ejercer (conocer, actualizar,
          rectificar, solicitar prueba de la autorización otorgada,
          revocarla, suprimir, acceder a la información) Firma (si aplica) y
          número de identificación. El término máximo previsto por la ley
          para resolver su reclamación es de quince (15) días hábiles,
          contado a partir del día siguiente a la fecha de su recibo. Cuando
          no fuere posible atender el reclamo dentro de dicho término,
          MEDIOS CONCEPT2B SAS informará al interesado los motivos de la
          demora y la fecha en que se atenderá su reclamo, la cual en ningún
          caso podrá superar los ocho (8) días hábiles siguientes al
          vencimiento del primer término. Una vez cumplidos los términos
          señalados por la Ley 1581 de 2012 y las demás normas que la
          reglamenten o complementen, el Titular al que se deniegue, total o
          parcialmente, el ejercicio de los derechos de acceso,
          actualización, rectificación, supresión y revocación, podrá poner
          su caso en conocimiento de la Superintendencia de Industria y
          Comercio – Delegatura para la Protección de Datos Personales-.
        </p>
        <p class="texto-modal">
          <b>VIGENCIA:</b> La presente Política para el Tratamiento de Datos
          Personales rige a partir del 1 de agosto de 2019. Las bases de
          datos en las que se registrarán los datos personales tendrán una
          vigencia igual al tiempo en que se mantenga y utilice la
          información para las finalidades descritas en esta política. Una
          vez se cumpla(n) esa(s) finalidad(es) y siempre que no exista un
          deber legal o contractual de conservar su información, sus datos
          serán eliminados de nuestras bases de datos. Otros ejemplos de
          período de permanencia de los datos en la base, son los
          siguientes: Los datos personales proporcionados se conservarán
          mientras se mantenga la relación contractual con el Titular de la
          información. Los datos personales proporcionados se conservarán
          mientras no se solicite su supresión por el interesado y siempre
          que no exista un deber legal de conservarlos. Los datos personales
          proporcionados se conservarán durante un plazo de 2 años a partir
          de la última confirmación de interés de parte del Titular.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label class="texto-iniciar">Aviso de privacidad</label>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p class="texto-modal">
          El presente Aviso de Privacidad (en adelante el “Aviso”) establece
          los términos y condiciones en virtud de los cuales MEDIOS
          CONCEPT2B SAS, identificado con NIT 901.331.727 - 4 y con
          domicilio en Calle 106 No 57-23 of 402 de Bogotá, realizará el
          tratamiento de sus datos personales.
        </p>
        <p class="texto-modal">
          <b>1. TRATAMIENTO Y FINALIDAD:</b> El tratamiento que realizará
          MEDIOS CONCEPT2B SAS con la información personal será el
          siguiente: La recolección, almacenamiento, uso, circulación y
          traslado para las siguientes finalidades:
        </p>
        <p class="texto-modal">
          1. Efectuar las gestiones pertinentes para el desarrollo del
          objeto social de la compañía en lo que tiene que ver con el
          cumplimiento del objeto del contrato celebrado con el Titular de
          la información.
        </p>
        <p class="texto-modal">
          2. Realizar invitaciones a eventos y ofrecer nuevos productos y
          servicios.
        </p>
        <p class="texto-modal">
          3. Gestionar trámites (solicitudes, quejas, reclamos).
        </p>
        <p class="texto-modal">
          4. Efectuar encuestas de satisfacción respecto de los bienes y
          servicios ofrecidos por MEDIOS CONCEPT2B SAS
        </p>
        <p class="texto-modal">
          5. Suministrar información de contacto a la fuerza comercial y/o
          red de distribución, telemercadeo, investigación de mercados y
          cualquier tercero con el cual MEDIOS CONCEPT2B SAS tenga un
          vínculo contractual para el desarrollo de actividades de ese tipo
          (investigación de mercados y telemercadeo, eventos, reuniones,
          proyectos digitales, etc) para la ejecución de las mismas.
        </p>
        <p class="texto-modal">
          6. Contactar al Titular a través de medios telefónicos para
          realizar encuestas, estudios y/o confirmación de datos personales
          necesarios para la ejecución de una relación contractual.
        </p>
        <p class="texto-modal">
          7. Contactar al Titular a través de medios electrónicos – SMS o
          chat para el envío de noticias relacionadas con campañas de
          fidelización, eventos, reuniones, proyectos digitales o mejora de
          servicio.
        </p>
        <p class="texto-modal">
          8. Transmitir los datos personales fuera del país a terceros con
          los cuales MEDIOS CONCEPT2B SAS haya suscrito un contrato de
          procesamiento de datos y sea necesario entregársela para el
          cumplimiento del objeto contractual.
        </p>
        <p class="texto-modal">
          9. Suministrar la información a terceros con los cuales MEDIOS
          CONCEPT2B tenga relación contractual y que sea necesario
          entregársela para el cumplimiento del objeto contratado.
        </p>
        <p class="texto-modal">
          <b>2. DERECHOS DEL TITULAR:</b> Como titular de sus datos
          personales Usted tiene derecho a:
        </p>
        <p class="texto-modal">
          (i) Acceder en forma gratuita a los datos proporcionados que hayan
          sido objeto de tratamiento.
        </p>
        <p class="texto-modal">
          (ii) Conocer, actualizar y rectificar su información frente a
          datos parciales, inexactos, incompletos, fraccionados, que
          induzcan a error, o a aquellos cuyo tratamiento esté prohibido o
          no haya sido autorizado.
        </p>
        <p class="texto-modal">
          (iii) Solicitar prueba de la autorización otorgada.
        </p>
        <p class="texto-modal">
          (iv) Presentar ante la Superintendencia de Industria y Comercio
          (SIC) quejas por infracciones a lo dispuesto en la normatividad
          vigente.
        </p>
        <p class="texto-modal">
          (v) Revocar la autorización y/o solicitar la supresión del dato,
          siempre que no exista un deber legal o contractual que impida
          eliminarlos.
        </p>
        <p class="texto-modal">
          (vi) Abstenerse de responder las preguntas sobre datos sensibles.
          Tendrá carácter facultativo las respuestas que versen sobre datos
          sensibles o sobre datos de las niñas y niños y adolescentes.
        </p>
        <p class="texto-modal">
          <b>3. MECANISMOS PARA CONOCER LA POLÍTICA DE TRATAMIENTO</b> El
          Titular puede acceder a nuestra Política de Tratamiento de
          información, la cual se encuentra publicada en www.agenciab2b.co,
          www.gransalonferretero.com, www.yoferretero.com
        </p>
      </div>
    </div>
  </div>
</div>
