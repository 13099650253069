<div class="container mx-auto margintT-20 marginB-20">
  <div class="row  marginT-20 ali-vertical">
    <div class="col-12 col-md-6 paddingL-0">
      <div class="texto-info" (click)="onVolver()">
        <h5>
          < Volver</h5>
      </div>
    </div>
  </div>
  <div class="row marginT-20 " *ngIf="cargado">
    <div class="col-12 col-md-6">
      <div class="row marginL-0 marginR-0">
        <div class="col-12">
          <label class="poppins texto-iniciar">
            {{ oferta.nombre }}
          </label>
        </div>
        <div class="col-12">
          <p class="texto marginT-0 marginB-0">
            {{ oferta.proveedor }} - {{ oferta.ciudad }}
          </p>
          <p class="texto  marginT-0 marginB-10">
            Ref: {{ oferta.referencia }}
          </p>
        </div>
      </div>
      <div class="row marginB-10">
        <div class="col-12" *ngIf="oferta.imagenes.length > 0">
          <carousel cellsToShow="1" dots="true" arrowsTheme="dark">
            <div class="carousel-cell" *ngFor="let img of imagenesCargadas">
              <img [src]="img.url" />
            </div>
          </carousel>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="row">
        <div class="col-12 col-sm-6 my-auto">
          <div class="div-descuento">
            <span>{{ oferta.descuento }}%</span> de descuento
          </div>
        </div>
        <div class="col-12 col-sm-6  my-auto">
          <div *ngIf="!usuarioLogueado" class="ali-vertical">
            <i class="fa fa-thumbs-o-up  iconos" alt="Debe iniciar sesión para dar like"></i>
            <div class="likes">{{ oferta.likes | shortnumber }}</div>
          </div>
          <div *ngIf="usuarioLogueado" class="ali-vertical">
            <span class="ion ion-md-share font-20 iconos" (click)="modalCompartir = true"></span>
            <i *ngIf="save" class="fa fa-bookmark iconos" (click)="toogleSave()"></i>
            <i *ngIf="!save" class="fa fa-bookmark-o iconos" (click)="toogleSave()"></i>
            <i *ngIf="like" class="fa fa-thumbs-up iconos" (click)="toogleLike()"></i>
            <i *ngIf="!like" class="fa fa-thumbs-o-up  iconos" (click)="toogleLike()"></i>
            <div class="likes">{{ oferta.likes | shortnumber }}</div>
          </div>
        </div>
      </div>
      <div class="row marginT-20">
        <div class="col-12 marginB-10 marginT-10">
          <label class="poppins texto-iniciar">
            Detalles
          </label>
        </div>
        <div class="col-12" [innerHtml]="sanitizer.bypassSecurityTrustHtml(oferta.detalle)">

        </div>
        <div class="col-12 marginB-10 marginT-10">
          <label class="poppins texto-iniciar">
            Condiciones
          </label>
        </div>
        <div class="col-12" [innerHtml]="sanitizer.bypassSecurityTrustHtml(oferta.condiciones)">

        </div>
      </div>
      <div class="row" *ngIf="usuarioLogueado">
        <div class="col-12 col-md-6">
          <button expand="block" type="button" class="boton-iniciar" (click)="solicitar = true">
            Solicitar cotización
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div [hidden]="!solicitar" *ngIf="usuario">
  <modal-contacto [param]="datosEnvio" [usuario]="usuario" (enviado)="onEnviado($event)"></modal-contacto>
</div>
<div [hidden]="!modificando">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p class="texto-blanco">Por favor espere...</p>
  </ngx-spinner>
</div>
<div *ngIf="modalCompartir">
  <div class="fondo-modal"></div>
  <div class="div-modal">
    <div class="row">
      <div class="col-12">
        <label class="titulo-modal float-r cursor" (click)="modalCompartir = false">X</label>
        <h6 class="texto-titulo2">
          Compartir
        </h6>
      </div>
      <div class="col-sm-2"></div>
      <div class="col-sm-2">
        <div class="boton-compartir">
          <button shareButton="facebook" [url]="enlaceShared()" class="btn btn-facebook" (click)="compartiendo()">
            <i class="fa fa-facebook"></i>
          </button>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="boton-compartir">
          <button shareButton="twitter" [url]="enlaceShared()" (click)="compartiendo()"
            style="color: white; background: #4AB3F4 !important;width: 100%  !important; font-size: 20px  !important;"
            class="btn btn-twitter">
            <i class="fa fa-twitter"></i>
          </button>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="boton-compartir">
          <button shareButton="linkedin" [url]="enlaceShared()" (click)="compartiendo()"
            style="color: white; background: #1178B3 !important;width: 100%  !important; font-size: 20px  !important;"
            class="btn btn-linkedin">
            <i class="fa fa-linkedin"></i>
          </button>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="boton-compartir">
          <button shareButton="whatsapp" [url]="enlaceShared()" class="btn btn-whatsapp" (click)="compartiendo()">
            <i class="fa fa-whatsapp"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div [hidden]="cargado">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p class="texto-blanco">Cargando...</p>
  </ngx-spinner>
</div>
