import { NgModule } from '@angular/core';
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { FerreofertasComponent } from './pages/ferreofertas/ferreofertas.component';
import { ProveedoresComponent } from './pages/proveedores/proveedores.component';
import { JuegoComponent } from './pages/juego/juego.component';
import { HoroscopoComponent } from './pages/horoscopo/horoscopo.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { NoticiaDetalleComponent } from './pages/noticia-detalle/noticia-detalle.component';
import { DetalleOfertaComponent } from './pages/Components/detalle-oferta/detalle-oferta.component';
import { YoaprendoComponent } from './pages/yoaprendo/yoaprendo.component';
import { YoaprendoDetalleComponent } from './pages/yoaprendo-detalle/yoaprendo-detalle.component';
import { PageTerminosCondicionesComponent } from './pages/page-terminos-condiciones/page-terminos-condiciones.component';
import { DeleteAccountComponent } from './pages/delete-account/delete-account.component';

const routes: Routes = [
  { path: '', component: HomePageComponent },
  { path: 'actualidad', component: HomePageComponent },
  {
    path: 'terminos-y-condiciones',
    component: PageTerminosCondicionesComponent,
  },
  {
    path: 'eliminar-mi-cuenta-en-plataforma',
    component: DeleteAccountComponent,
  },
  
  //{ path: 'ferreofertas', component: FerreofertasComponent },
  { path: 'proveedores', component: ProveedoresComponent },
  { path: 'yoaprendo', component: YoaprendoComponent },
  { path: 'juego', component: JuegoComponent },
  //{ path: 'horoscopo', component: HoroscopoComponent },
  { path: 'noticias/:id', component: NoticiaDetalleComponent },
  { path: 'perfil', component: PerfilComponent },
  { path: 'perfil/:tab', component: PerfilComponent },
  { path: 'detalle-oferta/:id', component: DetalleOfertaComponent },
  { path: 'video/:id', component: YoaprendoDetalleComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
