<div class="container mx-auto" *ngIf="cargado">
  <div class="row ">
    <div class="col-sm-12 col-md-8 ">
      <h5 class="texto-titulo ">
        {{ noticia.titulo }}
      </h5>
      <div>
        <div class="row ">
          <div class="col-4 ">
            <div class="texto-info3 my-auto">{{ noticia.autor }}</div>
          </div>
          <div class="col-3 col-sm-4 col-md-4 col-lg-5 col-xl-4 ">
            <fa-icon
              class="icon-clock float-left"
              [icon]="faClock"
            >  </fa-icon>
            <div class="texto-info5"> {{ noticia.fechaPublicacion | datediff }}</div>

          </div>

          <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-4 ">
            <div *ngIf="!usuarioLogueado">
              <div class="row">
                <div class="col-1 ">
                  <div class="likes my-auto">{{ noticia.likes + 40 |shortnumber}}</div>
                </div>
                <div class="col-2 ">
                  <img
                    src="/assets/img/like-off.png"
                    class="iconos-extras"
                    (click)="toogleLike()"
                    alt="Debes iniciar sesión para dar like"
                  />
                </div>
              </div>
            </div>
            <div *ngIf="usuarioLogueado">
              <div class="row">
                <div class="col-1 ">
                  <div class="likes my-auto">{{ noticia.likes + 40 |shortnumber}}</div>
                </div>
                <div class="col-1 ">
                  <img
                    *ngIf="!like"
                    src="/assets/img/like-off.png"
                    class="iconos-extras"
                    (click)="toogleLike()"
                  />
                  <img
                    *ngIf="like"
                    src="/assets/img/like-on.png"
                    class="iconos-extras"
                    (click)="toogleLike()"
                  />
                </div>
                <div class="col-1 ">
                  <img
                    *ngIf="save"
                    src="/assets/img/guardar-on.png"
                    class="iconos-extras2"
                    (click)="toogleSave()"
                  />
                  <img
                    *ngIf="!save"
                    src="/assets/img/guardar-off.png"
                    class="iconos-extras2"
                    (click)="toogleSave()"
                  />
                </div>
                <div class="col-1 ">
                  <span class="ion ion-md-share font-20" (click)="modalCompartir = true"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <img [src]="noticia.imagen" class="img-fluid marginB-20"/>
          </div>
          <div class="col-12">
            <div
                  class="video-responsive"
                  *ngIf="video"
                >
                  <iframe
                    [src]="video"
                    frameborder="0"
                    allowfullscreen="allowfullscreen"
                  ></iframe>
                </div>
          </div>
        </div>
      </div>

      <div class="texto-info2" [innerHtml]="sanitizer.bypassSecurityTrustHtml(noticia.contenido)"></div>
      <lista-comentarios [comentarios]="listaComentarios"></lista-comentarios>
      <crear-comentario
          (comentarioCreado)="onComentarioCreado($event)"
          [noticia]="noticia"
          *ngIf="usuarioLogueado && puedeComentar"
        ></crear-comentario>
    </div>

    <div class="col-sm-12 col-md-4">
      <div class="left-container ">
        <p class="texto-titulo">
          Noticias destacadas
        </p>
        <div *ngFor="let item of listaNoticias.slice(0, 3)">
          <tarjeta-noticia-sinimagen [item]="item"></tarjeta-noticia-sinimagen>
        </div>
      </div>
      <img class="img-fluid marginB-20 marginT-20" [src]="bannerSuperior.imagen.url"/>
      <img class="img-fluid" [src]="bannerInferior.imagen.url"/>
    </div>
  </div>
</div>
<main role="main" class="fondo">
  <ngx-spinner
    bdColor="rgba(0, 0, 0, 0.5)"
    size="medium"
    color="#fff"
    type="square-jelly-box"
    [fullScreen]="true"
    ><p class="texto-blanco">Espere por favor...</p></ngx-spinner
  >
</main>
<div *ngIf="modalCompartir">
  <div class="fondo-modal"></div>
  <div class="div-modal">
    <div class="row">
      <div class="col-12">
        <label class="titulo-modal float-r cursor" (click)="modalCompartir = false"
          >X</label
        >
        <h6 class="texto-titulo2">
          Compartir
        </h6>
      </div>

      <div class="col-sm-2"></div>
      <div class="col-sm-2">
        <div class="boton-compartir">
          <button
            shareButton="facebook"
            class="btn btn-facebook"
            [url]="enlaceShared()"
            (click)="compartiendo()"
          >
            <i class="fa fa-facebook"></i>
          </button>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="boton-compartir">
          <button
            shareButton="twitter"
            [url]="enlaceShared()"
            style="color: white; background: #4AB3F4 !important;width: 100%  !important; font-size: 20px  !important;"
            class="btn btn-twitter"
            (click)="compartiendo()"
          >
            <i class="fa fa-twitter"></i>
          </button>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="boton-compartir">
          <button
            shareButton="linkedin"
            [url]="enlaceShared()"
            style="color: white; background: #1178B3 !important;width: 100%  !important; font-size: 20px  !important;"
            class="btn btn-linkedin"
            (click)="compartiendo()"
          >
            <i class="fa fa-linkedin"></i>
          </button>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="boton-compartir">
          <button
            shareButton="whatsapp"
            [url]="enlaceShared()"
            class="btn btn-whatsapp"
            (click)="compartiendo()"
          >
            <i class="fa fa-whatsapp"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
